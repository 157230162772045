export interface GeneralPortalSettings {
    showServiceList: boolean,
    showIndividualFees: boolean,
    allowForChangingContactInfo: boolean,
}

export enum PracticeIdentityStyleForLogin {
    LOGO = "LOGO",
    NAME = "NAME",
    NONE = "NONE",
}

export interface LoginScreenSettings {
    "useEmojis": boolean
    "emojis": string[] // ["👋", "🙌", "💪"]
    "backgroundGradientCol": string[] // ["#011D4F", "#3880AB"]
    "backgroundGradientDeg": number // 135
    "practiceIdentityStyle": PracticeIdentityStyleForLogin // when there's no logo, "LOGO" type will display the name
}