import { faDownload, faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Button, Alert } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import GenericModal from "../../components/GenericModal";
import { SigningRequestExtended } from "../../lib/dataDefinitions/signingRequest";
import { useSigningRequest } from "../../lib/hooks/extData/signingRequest";
import useFileDownload from "../../lib/hooks/fileDownload";

const SignedDocumentPreviewModal: React.FC<{ request: SigningRequestExtended, onClose: Function }> = ({ request, onClose }) => {
  const { previewElemRS, signingRequestApi } = useSigningRequest();
  const { downloadFile, previewFile } = useFileDownload(previewElemRS.data?.url || '', previewElemRS.data?.filename || '');

  const closeModal = useCallback(() => {
    if (previewElemRS.data?.url) {
      URL.revokeObjectURL(previewElemRS.data.url)
    }
    onClose()
  }, [previewElemRS, onClose])

  useEffect(() => {
    signingRequestApi.downloadSigned(request.id, true);
  }, [signingRequestApi, request])

  return <GenericModal title="Signed document preview" open={true} onClose={() => closeModal()} style={{ width: '100%', maxWidth: 900 }} showCloseBtn={true}>
    {previewElemRS.pending ? <BoxedCircularProgress align="center" m={2} /> :
      previewElemRS.error ? <Alert severity="error">{previewElemRS.error}</Alert> :
        <Box>
          <Box>
            <BrowserView>
              {previewElemRS.data?.url ?
                <embed src={previewElemRS.data?.url} type="application/pdf" style={{ width: '100%', height: 'calc(100vh - 180px)' }}></embed> :
                <BoxedCircularProgress />}
            </BrowserView>
          </Box>
          <Stack sx={{ mt: 1 }} spacing={1} direction="row" justifyContent="center">
            <MobileView>
              <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={faEye} />} onClick={previewFile}>
                preview
              </Button>
            </MobileView>
            <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={faDownload} />} onClick={downloadFile}>
              download
            </Button>
          </Stack>
        </Box>}
  </GenericModal>
}

export default SignedDocumentPreviewModal;