import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Drawer from "@mui/material/Drawer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb, FsActualElemType, FsElem, FsElemOrigin, iconClasses } from "../../lib/dataDefinitions/fsElem";
import { useFsElem } from "../../lib/hooks/extData/fsElem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes, faFile, faEye, faFolder, faUpload, faFolderPlus, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useClient } from "../../lib/hooks/extData/clients";
import Button from '@mui/material/Button'
import FsElemDetails from "./FsElemDetails";
import IconButton from '@mui/material/IconButton'
import NewFolderModal from "./NewFolderModal";
import UploadFileModal from "./UploadFileModal";
import { useSearchParams } from "react-router-dom";
import { ClientStatus } from "../../lib/dataDefinitions/client";

interface FsElemExtendedId {
  clientId: number | null,
  fsElemOrigin: FsElemOrigin,
  id: number | null,
}

const ROOT_BREADCRUMBS: Breadcrumb[] = [{ label: 'Root folder', idType: FsElemOrigin.ACT, id: null }];

const ElemIcon: React.FC<{ elem: FsElem }> = ({ elem }) => <FontAwesomeIcon icon={elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR ? faFolder : (iconClasses[elem.content_type] || iconClasses[elem.content_type.split('/')[0]] || faFile)} />

const FilesView = () => {
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();

  const { clientRS, clientApi } = useClient();
  const { folderAndChildrenRS, breadcrumbsRS, fsActualElemApi } = useFsElem();

  const [currFsElemExtendedId, setCurrFsElemExtendedId] = useState<FsElemExtendedId>({ clientId: null, fsElemOrigin: FsElemOrigin.ACT, id: null });
  const currDir = useMemo(() => folderAndChildrenRS.data?.actualDir || folderAndChildrenRS.data?.templateDir || null, [folderAndChildrenRS]);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(ROOT_BREADCRUMBS);

  const [elemForDetailsId, setElemForDetailsId] = useState<{ fsElemOrigin: FsElemOrigin, id: number } | null>(null);
  const [elemForDetails, setElemForDetails] = useState<FsElem | null>(null);

  const [newFolderModalVisible, setNewFolderModalVisible] = useState(false);
  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);

  const goToFolder = (id: number | null, fsElemOrigin: FsElemOrigin) => {
    if (!clientId) return;
    if (!id) {
      setCurrFsElemExtendedId({ clientId: parseInt(clientId), fsElemOrigin: FsElemOrigin.ACT, id: null });
      return;
    }
    setCurrFsElemExtendedId({ clientId: parseInt(clientId), fsElemOrigin, id });
  }

  const refreshCurrentFolder = () => {
    setCurrFsElemExtendedId(current => ({ ...current })); // refresh
  };

  const onNewFolderModalClose = (wasCreated: boolean) => {
    setNewFolderModalVisible(false)
    if (wasCreated) {
      refreshCurrentFolder();
    }
  }

  const onUploadFileModalClose = (shouldReload: boolean) => {
    setUploadFileModalVisible(false)
    if (shouldReload) {
      refreshCurrentFolder();
    }
  }

  const onDetailsDrawerClose = (performedAction: 'NONE' | 'RENAME' | 'DELETE' = 'NONE') => {
    if (performedAction === 'DELETE') {
      setElemForDetailsId(null);
      if (elemForDetails?.type === FsActualElemType.DIR) {
        const prevDir = breadcrumbs[breadcrumbs.length - 2];
        goToFolder(prevDir.id, prevDir.idType);
      } else {
        refreshCurrentFolder();
      }
    }
    if (performedAction === 'RENAME') {
      refreshCurrentFolder();
    }
    if (performedAction === 'NONE') {
      setElemForDetailsId(null);
    }
  }

  // 4. Restore elemForDetails after directory and children are reloaded
  useEffect(() => {
    if (elemForDetailsId) {
      const currDir = folderAndChildrenRS.data?.actualDir || folderAndChildrenRS.data?.templateDir;
      const currChildren = folderAndChildrenRS.data?.children;
      if (!currDir || !currChildren) return;
      const elemForDetails = currDir.fsElemOrigin === elemForDetailsId.fsElemOrigin && currDir.id === elemForDetailsId.id ? currDir :
        currChildren.find(elem => elem.id === elemForDetailsId.id && elem.fsElemOrigin === elemForDetailsId.fsElemOrigin);
      setElemForDetails(elemForDetails || null);
    } else {
      setElemForDetails(null);
    }
  }, [elemForDetailsId, folderAndChildrenRS]);

  // 3. Fetch folder and breadcrumbs after switching folders or clients
  useEffect(() => {
    if (breadcrumbsRS.data) {
      setBreadcrumbs([...ROOT_BREADCRUMBS, ...breadcrumbsRS.data.reverse()]);
    }
  }, [breadcrumbsRS])
  useEffect(() => {
    if (!currFsElemExtendedId.clientId) return;
    fsActualElemApi.getFolderAndChildren(currFsElemExtendedId.clientId, currFsElemExtendedId.fsElemOrigin, currFsElemExtendedId.id);
    if (!currFsElemExtendedId.id) {
      // Root breadcrumb
      setBreadcrumbs(ROOT_BREADCRUMBS);
    } else {
      fsActualElemApi.getBreadcrumbs(currFsElemExtendedId.clientId, currFsElemExtendedId.fsElemOrigin, currFsElemExtendedId.id);
    }
  }, [currFsElemExtendedId, fsActualElemApi]);

  // 2. React to folder change in URL Search Params and client ID
  useEffect(() => {
    if (!clientRS.data) return; // got latest client data
    console.log("Search params:", searchParams)
    const fsElemOrigin = searchParams.get('folderFsElemOrigin') as FsElemOrigin | null;
    const id = searchParams.get('folderId');
    if (!fsElemOrigin || !id || id === 'null') {
      setCurrFsElemExtendedId({
        clientId: clientRS.data.id,
        fsElemOrigin: FsElemOrigin.ACT,
        id: null,
      });
    } else {
      setCurrFsElemExtendedId({
        clientId: clientRS.data.id,
        fsElemOrigin,
        id: parseInt(id),
      })
    }
  }, [searchParams, clientRS])

  // 1. Client fetch
  useEffect(() => {
    if (!clientId) return;
    clientApi.getClient(parseInt(clientId));
  }, [clientApi, clientId]);

  return clientRS.pending ? <BoxedCircularProgress mt={3} /> :
    clientRS.error || !clientRS.data ? <Alert severity="error">{clientRS.error || 'Error fetching client data'}</Alert> : <Box>
      <Box>
        <Typography variant="h4" mb={1}>Files</Typography>
        <Typography variant="body1">{clientRS.data.name}</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Stack
          justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
          direction={{ xs: 'column', lg: 'row' }}
          spacing={{ xs: 1, lg: 2 }}>
          {/* Breadcrumbs for path */}
          <Box>
            <Typography variant="body2">Current path: </Typography>
            {breadcrumbsRS.pending ? <BoxedCircularProgress mt={1} align="left" /> :
              <Breadcrumbs maxItems={10} aria-label="breadcrumb">
                {breadcrumbs.map((crumb, index) => index < breadcrumbs.length - 1 ?
                  <Link key={index} underline="hover" color="inherit" sx={{ cursor: 'pointer' }} onClick={() => goToFolder(crumb.id, crumb.idType)}>
                    {crumb.label}
                  </Link> :
                  <Typography key={index} color="text.primary">{crumb.label}</Typography>)}
              </Breadcrumbs>}
          </Box>
          {/* Folder action buttons */}
          {folderAndChildrenRS.pending ? <BoxedCircularProgress mt={3} align="right" /> :
            folderAndChildrenRS.error ? <Alert severity="error">{folderAndChildrenRS.error || 'Error fetching data'}</Alert> :
              !currDir ? '' :
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', flexWrap: 'wrap', gap: 1 }}>
                  {currDir.client_perms[1] === '1' && clientRS.data.company_status === ClientStatus.ACTIVE &&
                    <Button variant="outlined"
                      onClick={() => setUploadFileModalVisible(true)}
                      startIcon={<FontAwesomeIcon icon={faUpload} />}>
                      Upload file
                    </Button>}
                  {currDir.client_perms[2] === '1' && clientRS.data.company_status === ClientStatus.ACTIVE &&
                    <Button variant="outlined"
                      onClick={() => setNewFolderModalVisible(true)}
                      startIcon={<FontAwesomeIcon icon={faFolderPlus} />}>
                      New folder
                    </Button>}
                  <Button variant="outlined"
                    onClick={() => setElemForDetailsId({ fsElemOrigin: currDir.fsElemOrigin || FsElemOrigin.ACT, id: currDir.id })}
                    startIcon={<FontAwesomeIcon icon={faInfoCircle} />}>
                    Folder details
                  </Button>
                </Box>}
        </Stack>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>Contents: </Typography>
        {/* Folder contents */}
        {folderAndChildrenRS.pending ? <BoxedCircularProgress mt={3} /> :
          folderAndChildrenRS.error ? <Alert severity="error">{folderAndChildrenRS.error || 'Error fetching data'}</Alert> :
            !folderAndChildrenRS.data ? '' :
              folderAndChildrenRS.data.children.length < 1 && breadcrumbs.length < 2 ? <Typography>(no contents)</Typography> :
                <Grid container spacing={2}>
                  {breadcrumbs.length > 1 && <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Paper sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }} onClick={() => goToFolder(breadcrumbs[breadcrumbs.length - 2].id, breadcrumbs[breadcrumbs.length - 2].idType)}>
                      <Typography variant="h3" color="#AAA"><FontAwesomeIcon icon={faChevronLeft} /></Typography>
                      <Typography variant="body1" color="initial">BACK</Typography>
                      <Typography variant="body2" sx={{ visibility: 'hidden' }}>placeholder</Typography>
                    </Paper>
                  </Grid>}
                  {folderAndChildrenRS.data.children.map((elem) => <Grid key={elem.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Paper
                      sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR ? goToFolder(elem.id, elem.fsElemOrigin || FsElemOrigin.ACT) : setElemForDetailsId({ fsElemOrigin: elem.fsElemOrigin || FsElemOrigin.ACT, id: elem.id })}>
                      <Typography variant="h3" color="#AAA">
                        <ElemIcon elem={elem} />
                      </Typography>
                      <Typography variant="body1" color="initial" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100vw - 80px)' }}>{elem.name}</Typography>
                      <Typography variant="body2" color={elem.client_perms === '100' || clientRS.data?.company_status !== ClientStatus.ACTIVE ? 'gray' : 'green'}
                        sx={{ visibility: elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR ? 'visible' : 'hidden' }}>
                        <FontAwesomeIcon icon={faEye} style={{ marginRight: 5 }} />
                        {elem.client_perms !== '100' && clientRS.data?.company_status === ClientStatus.ACTIVE && <FontAwesomeIcon icon={faUpload} style={{ marginRight: 5 }} />}
                        {elem.client_perms === '100' || clientRS.data?.company_status !== ClientStatus.ACTIVE ? 'Read-only' : 'Read & upload'}
                      </Typography>
                    </Paper>
                  </Grid>)}
                </Grid>}
      </Box>
      {/* Details drawer */}
      <Drawer anchor="right" open={elemForDetails != null} onClose={() => onDetailsDrawerClose()} PaperProps={{ sx: { width: '90%', maxWidth: '400px' } }}>
        {elemForDetails && <Box p={2}>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="h4">
              <ElemIcon elem={elemForDetails} />
              <Typography sx={{ ml: 1 }} variant="h4" component="span">{elemForDetails.fsElemOrigin === FsElemOrigin.TPL || elemForDetails.type === FsActualElemType.DIR ? 'Folder' : 'File'} details</Typography>
            </Typography>
            <IconButton aria-label="close file details" onClick={() => onDetailsDrawerClose()}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Stack>
          <FsElemDetails fsElem={elemForDetails} onClose={onDetailsDrawerClose} />
        </Box>}
      </Drawer>
      {newFolderModalVisible && <NewFolderModal
        onClose={(wasCreated: boolean) => onNewFolderModalClose(wasCreated)}
        clientId={clientId ? parseInt(clientId) : -1}
        currDir={currDir}
      ></NewFolderModal>}
      {uploadFileModalVisible && <UploadFileModal
        onClose={(wasCreated: boolean) => onUploadFileModalClose(wasCreated)}
        clientId={clientId ? parseInt(clientId) : -1}
        currDir={currDir}
      ></UploadFileModal>}
    </Box>
}


export default FilesView;