import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, Grid, Card, CardContent, Stack, Chip, Button, FormControlLabel, Checkbox, Snackbar, Alert } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedDate } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import InfoElem from "../../components/InfoElem";
import { useClient } from "../../lib/hooks/extData/clients";
import { DATA_FORM_REQUEST_STATE_MAPPING, DataFormRequest, DataFormRequestState } from "../../lib/dataDefinitions/formRequest";
import { useFormRequest } from "../../lib/hooks/extData/formRequest";
import { useFormDef } from "../../lib/hooks/extData/formDef";
import DataFormRequestDetailModal from "./FormRequestDetailModal";

const CardInfoElem: React.FC<{ label: string, value: any }> = ({ label, value }) => <InfoElem label={label} value={value} sx={{ mb: 1 }} />

const FormRequestsView: React.FC = () => {
  const { clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { clientRS, clientApi } = useClient();
  const { formDefForClientRequestsObjRS, formDefApi } = useFormDef();
  const { formRequestsRS, formRequestApi } = useFormRequest();

  const [formRequests, setFormRequests] = useState<DataFormRequest[]>([]);
  const [shouldIncludeFinished, setShouldIncludeFinished] = useState<boolean>(false);
  const finishedRequestsCount = useMemo(() => (formRequestsRS.data || []).filter(elem => elem.state === DataFormRequestState.FINISHED).length, [formRequestsRS.data]);

  const [requestForDetails, setRequestForDetails] = useState<DataFormRequest | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<{ type: 'SUCCESS' | 'INFO', text: string } | null>(null);

  const filterRequests = useCallback(() => {
    const unfiltered = formRequestsRS.data || [];
    setFormRequests(unfiltered.filter(elem => (
      (shouldIncludeFinished || elem.state !== DataFormRequestState.FINISHED)
    )))
  }, [formRequestsRS, shouldIncludeFinished])

  const getFormRequests = useCallback(() => {
    if (!clientId || isNaN(parseInt(clientId))) return;
    formRequestApi.getForClient(parseInt(clientId));
  }, [clientId, formRequestApi]);

  const getFormDefs = useCallback(() => {
    if (!clientId || isNaN(parseInt(clientId))) return;
    formDefApi.getLimitedForFormRequestsObj(parseInt(clientId));
  }, [clientId, formDefApi]);

  const startWorkingOnRequest = (request: DataFormRequest) => {
    setSearchParams({ reqId: request.id.toString() });
  }

  const finishWorkingOnRequest = (reloadReason: 'REQ_FINISHED' | 'RUN_UPDATED' | null) => {
    if (reloadReason !== null) {
      getFormData();
    }
    if (reloadReason === 'REQ_FINISHED') {
      setSnackbarMessage({ type: 'SUCCESS', text: 'Request finished successfully' })
    }
    setSearchParams({ reqId: '' });
  }

  const getFormData = useCallback(() => {
    getFormRequests();
    getFormDefs();
  }, [getFormRequests, getFormDefs]);

  useEffect(() => {
    const reqToWorkOn = formRequests.find(elem => (
      elem.id === parseInt(searchParams.get('reqId') || '')
    ));
    setRequestForDetails(reqToWorkOn || null);
  }, [searchParams, formRequests])

  useEffect(() => filterRequests(), [
    filterRequests,
    shouldIncludeFinished,
    formRequestsRS,
  ])

  // Get initial data
  useEffect(() => {
    if (!clientId || isNaN(parseInt(clientId))) return;
    clientApi.getClient(parseInt(clientId));
    getFormData();
  }, [clientId, clientApi, getFormData])

  return <Box>
    {snackbarMessage !== null && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={true} autoHideDuration={6000} onClose={() => setSnackbarMessage(null)}>
      <Alert onClose={() => setSnackbarMessage(null)} severity={snackbarMessage.type === 'SUCCESS' ? 'success' : 'info'} sx={{ width: '100%' }}>
        {snackbarMessage.text}
      </Alert>
    </Snackbar>}
    {clientRS.pending ? <BoxedCircularProgress align="center" mt={2} /> :
      clientRS.error || !clientRS.data ? <Typography color="error">{clientRS.error || 'Data not loaded'}</Typography> : <Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4" mb={1}>Form requests</Typography>
          <Typography variant="body1">{clientRS.data.name}</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <FormControlLabel control={<Checkbox checked={shouldIncludeFinished} onChange={e => setShouldIncludeFinished(e.target.checked)} />} label={"Include finished requests (" + finishedRequestsCount + ")"} />
            </Grid>
            <Grid item xs={12} md>
              <Typography variant="body1"><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> Showing newest requests first.</Typography>
            </Grid>
          </Grid>
        </Box>
        {formRequestsRS.pending || formDefForClientRequestsObjRS.pending ? <BoxedCircularProgress align="center" mt={2} /> :
          formRequestsRS.error || !formRequestsRS.data ? <Typography color="error">{formRequestsRS.error || 'Data not loaded'}</Typography> : <Box>
            {!formRequests.length ? <Typography>(no requests to show)</Typography> : <Grid container spacing={2} alignItems="stretch">
              {formRequests.map((request) => <Grid item key={request.id} xs={12} md={6} lg={4} xl={3}>
                <Card sx={{ height: '100%' }}>
                  <CardContent sx={{ height: '100%' }}>
                    <Stack sx={{ height: '100%' }} justifyContent='space-between'>
                      <Box sx={{ mb: 2 }}>
                        <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100vw - 80px)' }} variant="h6" color="initial">
                          {formDefForClientRequestsObjRS.data?.[request.form_def_id]?.name || '(name not loaded)'}
                        </Typography>
                        <Stack sx={{ mt: 1, mb: 2 }} direction="row" alignItems="baseline">
                          <Chip
                            icon={<Box sx={{ pl: 1 }}>
                              <FontAwesomeIcon icon={DATA_FORM_REQUEST_STATE_MAPPING[request.state].icon} />
                            </Box>}
                            label={DATA_FORM_REQUEST_STATE_MAPPING[request.state].label}
                            color={DATA_FORM_REQUEST_STATE_MAPPING[request.state].color}
                          />
                          {request.finish_date && <Typography sx={{ ml: 1 }} variant="body1" color="initial">on: <FormattedDate value={request.finish_date} dateStyle="short" timeStyle="short" /></Typography>}
                        </Stack>
                        <CardInfoElem label="Requested" value={<FormattedDate value={request.create_date} dateStyle="short" timeStyle="short" />} />
                        {request.create_comment && <CardInfoElem label="Creator comment" value={request.create_comment} />}
                        {request.state === DataFormRequestState.AMEND_REQ && request.amend_req_reason && <CardInfoElem label="Reason for requesting amendment" value={request.amend_req_reason} />}
                        {request.state === DataFormRequestState.FINISHED && request.finish_comment && <CardInfoElem label="Responder comment" value={request.finish_comment} />}
                      </Box>
                      <Box>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                          <Button variant="outlined" color="primary" onClick={() => startWorkingOnRequest(request)}>{request.state === DataFormRequestState.FINISHED ? 'view answers' : 'respond'}</Button>
                        </Stack>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>)}
            </Grid>}
          </Box>}
        {
          requestForDetails && <DataFormRequestDetailModal request={requestForDetails} defsObj={formDefForClientRequestsObjRS.data || {}} onClose={(reloadReason) => finishWorkingOnRequest(reloadReason)} />
        }
      </Box>}
  </Box>
}

export default FormRequestsView;