import { useMemo, useState } from "react";
import { sendRequestUseRS } from "../../apiClient";
import { Client, LinkedClient } from "../../dataDefinitions/client";
import { RequestState } from "../../dataDefinitions/request";

// No need to pass user ID as server takes it from JWT token
export const useLinkedClients = () => {
  const [linkedClientsRS, setLinkedClientsRS] = useState<RequestState<LinkedClient[]>>({ pending: false, error: '', data: null });

  const linkedClientsApi = useMemo(() => ({
    getLinkedClients: async () => {
      await sendRequestUseRS(setLinkedClientsRS, 'GET', `/client/getListForCustomerUser/`);
    },
  }), []);

  return { linkedClientsRS, linkedClientsApi };
}

export const useClient = () => {
  const [clientRS, setClientRS] = useState<RequestState<Client>>({ pending: false, error: '', data: null });
  const [editClientRS, setEditClientRS] = useState<RequestState<{ returned: any }>>({ pending: false, error: '', data: null });

  const clientApi = useMemo(() => ({
    getClient: async (clientId: number) => {
      return await sendRequestUseRS(setClientRS, 'GET', `/client/${clientId}`);
    },
    editClient: async (clientId: number, fields: { [key: string]: any }) => {
      return await sendRequestUseRS(setEditClientRS, 'PATCH', `/client/${clientId}`, { body: { fields } });
    },
  }), []);

  return { clientRS, setClientRS, editClientRS, clientApi };
}