import { useState } from 'react';
import GenericModal from '../../components/GenericModal';
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useFsElem } from '../../lib/hooks/extData/fsElem';
import { FsElem, FsElemOrigin } from '../../lib/dataDefinitions/fsElem';
import BoxedCircularProgress from '../../components/BoxedCircularProgress';
import { Alert } from '@mui/material';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// onClose returns whether the creation was successful or not
const NewFolderModal: React.FC<{ clientId: number, currDir: FsElem | null, onClose: Function }> = ({ clientId, currDir, onClose }) => {
  const { createFolderRS, fsActualElemApi } = useFsElem();
  const [name, setName] = useState('');

  const createFolder = async () => {
    const success = await fsActualElemApi.createFolder(clientId, currDir?.fsElemOrigin || FsElemOrigin.ACT, currDir?.id || null, name);
    if (success) {
      onClose(true);
    }
  }

  return <GenericModal title="Create a folder" open={true} onClose={() => onClose(false)}>
    {createFolderRS.pending ? <BoxedCircularProgress /> : <>
      <TextField value={name} onChange={e => setName(e.target.value)} inputProps={{ maxLength: 255 }} fullWidth margin="normal" variant="outlined" label="Folder name"></TextField>
      {createFolderRS.error && <Alert severity="error">{createFolderRS.error}</Alert>}
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Button sx={{ mr: 1 }} variant="outlined" color="success" onClick={createFolder} startIcon={<FontAwesomeIcon icon={faCheck} />}>
          Confirm
        </Button>
        <Button variant="outlined" color="error" onClick={() => onClose(false)} startIcon={<FontAwesomeIcon icon={faTimes} />}>
          Cancel
        </Button>
      </Box>
    </>}
  </GenericModal>

}

export default NewFolderModal;