import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CSSProperties, useEffect, useState } from "react";
import QrCode from "../components/QrCode";
import { useAuth } from "../lib/hooks/extData/auth";
import { Divider, Stack } from "@mui/material";

const storeBadgeSx: CSSProperties = {
  width: '45vw',
  maxWidth: '200px',
}

const LinkMobileAppView = () => {

  const [qrData, setQrData] = useState('');
  const { loggedInClientRS, userSettled } = useAuth();

  useEffect(() => {
    if (!userSettled || !loggedInClientRS.data) return;
    const QR_DATA = {
      action: "SETUP_CLIENT",
      details: {
        domain: window.location.protocol + '//' + window.location.host,
        email: loggedInClientRS.data.email,
      }
    }
    setQrData('clientengager://' + JSON.stringify(QR_DATA))
  }, [loggedInClientRS, userSettled])

  return <Box>
    <Typography variant="h4" sx={{ mb: 3 }}>Link our mobile app</Typography>
    <Box sx={{ mb: 4 }}>
      <Typography sx={{ mb: 2 }}><strong>Step 1:</strong> Download the app 'Engager.app' from App Store or Google Play.</Typography>
      <Stack direction={'row'} alignItems={'center'} gap={'20px'} mt={1} flexWrap={'wrap'}>
        <Box>
          <a href="https://apps.apple.com/gb/app/client-engager/id6463508531"><img style={storeBadgeSx} src="/img/AppStoreDownload.svg" alt="Download on the App Store badge" /></a>
        </Box>
        <Box>
          <a href="https://play.google.com/store/apps/details?id=com.engagerapp.clientengagerapp"><img style={storeBadgeSx} src="/img/GooglePlayDownload.png" alt="Get it on Google Play badge" /></a>
        </Box>
      </Stack>
    </Box>
    <Box sx={{ mb: 4 }}>
      <Typography sx={{ mb: 2 }}><strong>Step 2:</strong> Open the app and scan the code below.</Typography>
      {/* <Typography sx={{mb:1}}>## QR data for debugging: <pre>{qrData}</pre></Typography> */}
      <QrCode textToEncode={qrData} />
    </Box>
    <Box sx={{ mb: 4 }}>
      <Typography><strong>Step 3:</strong> Put your Client Portal email and password in the app when prompted.</Typography>
    </Box>
    <Divider></Divider>
    <Box sx={{ mt: 1 }}>
      <Typography variant="caption" component="p">App Store and Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries.</Typography>
      <Typography variant="caption" component="p">Google Play and the Google Play logo are trademarks of Google LLC.</Typography>
    </Box>

  </Box>
}

export default LinkMobileAppView;