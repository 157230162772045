import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { sendRequest, sendRequestUseRS } from "../../apiClient";
import { accountantBranding } from "../../store/accountant";
import { RequestState } from "../../dataDefinitions/request";
import { AccountantContactInfo } from "../../dataDefinitions/accountant";


export const useAccountant = () => {
    const [branding, setDetails] = useRecoilState(accountantBranding);
    const [contactInfoRS, setContactInfoRS] = useState<RequestState<AccountantContactInfo>>({ pending: false, error: '', data: null })

    const accountantApi = useMemo(() => ({
        getBranding: async () => {
            const { data } = await sendRequest('GET', '/accountant/branding');
            setDetails(data);
        },
        getContactInfo: async () => {
            return await sendRequestUseRS(setContactInfoRS, 'GET', `/accountant/contactInfo`);
        }
    }), [setDetails]);

    return {branding, contactInfoRS, accountantApi}
}