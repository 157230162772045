import { useCallback } from "react";

const useFileDownload = (url: string, filename: string) => {

  const downloadFile = useCallback(() => {
    if(!url || !filename){
      console.error("No URL or filename to download");
      return;
    }
    const aHref = document.createElement('a');
    aHref.href = url;
    aHref.target = '_blank';
    aHref.download = filename;
    aHref.click();
  }, [url, filename])
  
  const previewFile = useCallback(() => {
    if(!url){
      console.error("No URL to preview");
      return;
    }
    const aHref = document.createElement('a');
    aHref.href = url;
    aHref.click();
  }, [url])

  return {
    downloadFile,
    previewFile
  }

}

export default useFileDownload;