import { useMemo, useState } from "react";
import { getDefaultRSObject, sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";
import { DataFormDef } from "../../dataDefinitions/formDef";

const BASE_URL = '/formDef';

export const useFormDef = () => {
  const [formDefForClientRequestsObjRS, setFormDefForClientRequestsObjRS] = useState<RequestState<{ [id: string]: DataFormDef }>>(getDefaultRSObject())
  const [formDefForRequestRS, setFormDefForRequestRS] = useState<RequestState<DataFormDef>>(getDefaultRSObject())

  const formDefApi = useMemo(() => ({
    getLimitedForFormRequestsObj: async (clientId: number) => {
      await sendRequestUseRS(setFormDefForClientRequestsObjRS, 'GET', `${BASE_URL}/limitedForFormRequests/${clientId}`, {
        dataPipe: [
          (values: DataFormDef[]) => values.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {})
        ]
      });
    },
    getForFormRequest: async (requestId: number) => {
      await sendRequestUseRS(setFormDefForRequestRS, 'GET', `${BASE_URL}/forFormRequest/${requestId}`);
    },
  }), []);

  return {
    formDefForClientRequestsObjRS, formDefForRequestRS,
    formDefApi
  }
}