import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from '@mui/system/Box';
import { FormattedNumber } from 'react-intl';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BoxedCircularProgress from "../components/BoxedCircularProgress";
import { useClient } from "../lib/hooks/extData/clients";
import { useServices } from "../lib/hooks/extData/services";
import { useServiceDefinitionsObj } from "../lib/hooks/extData/serviceDefinitions";
import { useAccountant } from "../lib/hooks/extData/accountant";
import { ClientStatus, ClientType } from "../lib/dataDefinitions/client";
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faSave, faTimes, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import Alert from "@mui/material/Alert";
import ProcessingDataInfo from "../components/ProcessingDataInfo";
import { useAuth } from "../lib/hooks/extData/auth";
import { useSettings } from "../lib/hooks/extData/settings";


enum InfoToEditType {
  EMAIL = 'email',
  MOBILE = 'mobile',
  TELEPHONE = 'telephone',
}
enum InfoToEditHasType {
  EMAIL = 'has_email',
  MOBILE = 'has_mobile',
  TELEPHONE = 'has_telephone',
}

const ClientDetailsView = () => {
  const INFOS_TO_EDIT: { label: string, type: InfoToEditType, hasType: InfoToEditHasType }[] = [
    { label: 'E-mail address', type: InfoToEditType.EMAIL, hasType: InfoToEditHasType.EMAIL },
    { label: 'Mobile number', type: InfoToEditType.MOBILE, hasType: InfoToEditHasType.MOBILE },
    { label: 'Telephone number', type: InfoToEditType.TELEPHONE, hasType: InfoToEditHasType.TELEPHONE },
  ]

  const { clientId } = useParams();
  const { loggedInUser, authApi } = useAuth();
  const { branding: accBranding } = useAccountant();
  const { generalSettings } = useSettings();
  const { clientRS, setClientRS, editClientRS, clientApi } = useClient();
  const { servicesRS, servicesApi } = useServices();
  const { serviceDefsObjRS, serviceDefsApi } = useServiceDefinitionsObj();

  const [infoToEditType, setInfoToEditType] = useState<[InfoToEditType, InfoToEditHasType] | null>(null);
  const [infoToEditValue, setInfoToEditValue] = useState('');
  const [infoToEditHasValue, setInfoToEditHasValue] = useState<boolean>(false);

  const startEditingInfo = (infoType: InfoToEditType, infoHasType: InfoToEditHasType) => {
    if (!clientRS.data) return;
    setInfoToEditValue(clientRS.data[infoType]);
    setInfoToEditHasValue(Boolean(clientRS.data[infoHasType]));
    setInfoToEditType([infoType, infoHasType]);
  }

  const saveEditedInfo = async () => {
    if (!infoToEditType || !clientRS.data) return;
    const editObj = {
      [infoToEditType[0]]: infoToEditHasValue ? infoToEditValue : '',
      [infoToEditType[1]]: infoToEditHasValue,
    };
    const success = await clientApi.editClient(clientRS.data?.id, editObj);
    if (success) {
      if ('email' in editObj && loggedInUser) {
        authApi.getLoggedInClient(loggedInUser.client_id);
      }
      cancelEditingInfo();
      setClientRS({
        pending: false, error: null, data: {
          ...clientRS.data,
          ...editObj
        }
      })
    }
  }

  const cancelEditingInfo = () => {
    setInfoToEditValue('');
    setInfoToEditType(null);
  }

  const saveContactPrefs = async (prefKey: string, value: boolean) => {
    if (!clientRS.data) return;
    const editObj = {
      [prefKey]: value
    }
    const success = await clientApi.editClient(clientRS.data?.id, editObj)
    if (success) {
      setClientRS({
        pending: false, error: null, data: {
          ...clientRS.data,
          ...editObj
        }
      })
    }
  }

  useEffect(() => {
    serviceDefsApi.getServiceDefs();
  }, [serviceDefsApi])

  useEffect(() => {
    if (clientId) {
      console.log("Calling to get client")
      cancelEditingInfo();
      const clientIdNum = parseInt(clientId);
      clientApi.getClient(clientIdNum);
      if (generalSettings?.showServiceList) {
        servicesApi.getServices(clientIdNum);
      }
    }
  }, [clientApi, servicesApi, clientId, generalSettings]);

  return clientRS.pending ? <BoxedCircularProgress align="center" mt={2} /> :
    clientRS.error || !clientRS.data ? <Typography color="error">{clientRS.error || 'Data not loaded'}</Typography> :
      <Box>
        <Box>
          <Typography variant="h4" mb={1}>{clientRS.data.name}</Typography>
          <Typography variant="body1">{clientRS.data.type}{clientRS.data.company_status !== ClientStatus.ACTIVE ? ` (${clientRS.data.company_status})` : ''}</Typography>
        </Box>
        {editClientRS.error && <Alert sx={{ mt: 1 }} severity="error">{editClientRS.error}</Alert>}
        {/* Showing progress wheel moves the UI down which looks bad for switches */}
        {editClientRS.pending && infoToEditType != null && <ProcessingDataInfo />}
        {clientRS.data.type === ClientType.IST || clientRS.data.type === ClientType.CON ? <Box>
          <Box mt={3}>
            <Typography variant="h5" mb={1}>Contact details</Typography>
            <Stack 
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 2, md: 4 }}>
              {INFOS_TO_EDIT.map((info) => <Box key={info.type}>
                {
                  infoToEditType?.[0] === info.type ? <Stack direction="row" alignItems="center">
                    <FormGroup>
                      <FormControlLabel
                        label={'Has ' + info.label.toLowerCase()}
                        sx={{ mb: 1 }}
                        control={
                          <Checkbox
                            checked={infoToEditHasValue}
                            onChange={e => setInfoToEditHasValue(e.target.checked)}
                            color="primary"
                          />
                        }
                      />
                      <TextField
                        id={info.type + "-tf"}
                        label={info.label}
                        value={infoToEditValue}
                        disabled={!infoToEditHasValue}
                        onChange={e => setInfoToEditValue(e.target.value)}
                      />
                    </FormGroup>
                    <IconButton sx={{ ml: 1 }} aria-label={"save " + info.label} onClick={() => saveEditedInfo()}>
                      <FontAwesomeIcon icon={faSave} />
                    </IconButton>
                    <IconButton sx={{ ml: 1 }} aria-label={"cancel editing " + info.label} onClick={() => cancelEditingInfo()}>
                      <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                  </Stack> :
                    <Box>
                      <Stack direction="row">
                        <Typography variant="subtitle1" sx={{ mr: 1 }}>{info.label}: </Typography>
                        {generalSettings?.allowForChangingContactInfo && (info.type !== InfoToEditType.EMAIL || loggedInUser?.client_id === clientRS.data?.id) &&
                          <Tooltip title="click to edit" arrow={true} sx={{ cursor: 'pointer' }} onClick={() => startEditingInfo(info.type, info.hasType)}>
                            <Typography variant="subtitle1"><FontAwesomeIcon icon={faPencil} style={{ color: '#AAA', cursor: 'pointer' }} /></Typography>
                          </Tooltip>}
                      </Stack>
                      <Typography variant="body1" component="div" fontSize={20}>{!clientRS.data?.[info.hasType] || !clientRS.data?.[info.type] ? '(not provided)' : clientRS.data?.[info.type]}</Typography>
                      {info.type === InfoToEditType.EMAIL && loggedInUser?.client_id !== clientRS.data?.id && <Typography variant="body2" sx={{ mt: 1 }}><FontAwesomeIcon icon={faInfoCircle} /> E-mail address is used to log in to Portal<br /> and can only be edited by its owner.</Typography>}
                    </Box>
                }
              </Box>)}
            </Stack>
          </Box>
          <Box mt={3}>
            <Typography variant="h5" mb={1}>Contact preferences</Typography>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}>
              <Box>
                <Typography variant="subtitle1">Essential contact:</Typography>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={Boolean(clientRS.data.essential_sms_allowed)} onChange={e => saveContactPrefs('essential_sms_allowed', e.target.checked)} />} label="Text message" />
                  <FormControlLabel control={<Switch disabled checked={Boolean(clientRS.data.essential_email_allowed)} />} label="E-mail (required)" />
                </FormGroup>
              </Box>
              <Box>
                <Typography variant="subtitle1">Marketing contact:</Typography>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={Boolean(clientRS.data.marketing_sms_allowed)} onChange={e => saveContactPrefs('marketing_sms_allowed', e.target.checked)} />} label="Text message" />
                  <FormControlLabel control={<Switch checked={Boolean(clientRS.data.marketing_email_allowed)} onChange={e => saveContactPrefs('marketing_email_allowed', e.target.checked)} />} label="E-mail" />
                </FormGroup>
              </Box>
            </Stack>
          </Box>
        </Box> : ''}
        {generalSettings?.showServiceList && clientRS.data.type !== ClientType.CON && <Box mt={3}>
          <Typography variant="h5" mb={1}>Services</Typography>
          {servicesRS.pending || serviceDefsObjRS.pending ? <BoxedCircularProgress align="left" mt={2} /> :
            servicesRS.error || serviceDefsObjRS.error || !servicesRS.data ? <Typography color="error">{servicesRS.error || serviceDefsObjRS.error || 'Data not loaded'}</Typography> :
              <Box>
                {!servicesRS.data.length && <Typography>(no services defined)</Typography>}
                <Grid container spacing={2} alignItems="stretch">
                  {servicesRS.data.sort((a, b) => b.fee - a.fee).map((service) => <Grid item key={service.id}  xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent sx={{ height: '100%' }}>
                        <Stack sx={{ height: '100%' }} justifyContent='space-between'>
                          <Typography variant="h6">{serviceDefsObjRS.data ? serviceDefsObjRS.data[service.type].full_name : ''}</Typography>
                          {generalSettings.showIndividualFees && <Box>
                            <Typography variant="body1" fontSize={26}>
                              <FormattedNumber
                                value={service.fee}
                                /* 'style' below is a part of FormattedNumber API but eslint treats it as generic 'style' prop, hence disabling */
                                /* eslint-disable-next-line */
                                style="currency"
                                currency={accBranding?.Currency} />
                            </Typography>
                            <Typography variant="body1" color="text.secondary">{service.fee_period}</Typography>
                          </Box>}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>)}
                </Grid>
              </Box>}
        </Box>}

      </Box>
}

export default ClientDetailsView;