import { useMemo, useState } from "react";
import { getDefaultRSObject, sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";
import { DataFormRun, DataFormRunValues } from "../../dataDefinitions/formRun";

const BASE_URL = '/formRun';

export const useFormRun = () => {
  const [formRunsForRequestRS, setFormRunsForRequestRS] = useState<RequestState<DataFormRun[]>>(getDefaultRSObject())
  const [formRunValuesRS, setFormRunValuesRS] = useState<RequestState<DataFormRunValues>>(getDefaultRSObject())
  const [existingClientValuesRS, setExistingClientValuesRS] = useState<RequestState<DataFormRunValues>>(getDefaultRSObject()) // same format as form run values
  const [createRunForRequestRS, setCreateRunForRequestRS] = useState<RequestState<number>>(getDefaultRSObject())
  const [updateRunRS, setUpdateRunRS] = useState<RequestState<'OK'>>(getDefaultRSObject())
  const [deleteRunRS, setDeleteRunRS] = useState<RequestState<'OK'>>(getDefaultRSObject())

  const formRunApi = useMemo(() => ({
    getForFormRequest: async (requestId: number) => {
      await sendRequestUseRS(setFormRunsForRequestRS, 'GET', `${BASE_URL}/forFormRequest/${requestId}`);
    },
    getRun: async (runId: number) => {
      await sendRequestUseRS(setFormRunValuesRS, 'GET', `${BASE_URL}/values/${runId}`);
    },
    getValues: async (runId: number) => {
      await sendRequestUseRS(setFormRunValuesRS, 'GET', `${BASE_URL}/values/${runId}`);
    },
    getExistingClientValues: async (requestId: number) => {
      await sendRequestUseRS(setExistingClientValuesRS, 'GET', `${BASE_URL}/existingClientValues/${requestId}`);
    },
    createRunForRequest: async (requestId: number, values: DataFormRunValues, comment: string | null) => {
      return await sendRequestUseRS(setCreateRunForRequestRS, 'POST', `${BASE_URL}/newForRequest/${requestId}`, {
        body: { values, comment }
      });
    },
    updateRun: async (runId: number, values: DataFormRunValues, comment: string | null) => {
      return await sendRequestUseRS(setUpdateRunRS, 'PATCH', `${BASE_URL}/${runId}`, {
        body: { values, comment }
      });
    },
    deleteRun: async (runId: number) => {
      return await sendRequestUseRS(setDeleteRunRS, 'DELETE', `${BASE_URL}/${runId}`);
    },
  }), []);

  const dismissDeleteError = () => setDeleteRunRS(getDefaultRSObject());
  
  return {
    formRunsForRequestRS, 
    formRunValuesRS, 
    existingClientValuesRS,
    createRunForRequestRS,
    updateRunRS,
    deleteRunRS,

    dismissDeleteError,

    formRunApi
  }
}