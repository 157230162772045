import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import IconButton from '@mui/material/IconButton'
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const builtInStyle: React.CSSProperties = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 500,
};

const GenericModal: React.FC<{ open: boolean, onClose: Function, showCloseBtn?: boolean, closeOnlyOnBtn?: boolean, title: string, style?: React.CSSProperties, children: React.ReactNode }> = ({ open, onClose, showCloseBtn, closeOnlyOnBtn = false, title, style = {}, children }) => {
  return <Modal
    open={open}
    onClose={() => {
      if (closeOnlyOnBtn) return
      onClose()
    }}
    aria-labelledby="modal-modal-title"
  >
    <Box style={{ ...builtInStyle, ...style }}>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="modal-modal-title" variant="h6">
            {title}
          </Typography>
          {showCloseBtn && <IconButton onClick={() => onClose()}>
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </IconButton>}
        </Stack>
        <Box sx={{ mt: 1 }} style={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto' }}>
          {children}
        </Box>
      </Paper>
    </Box>
  </Modal>
}

export default GenericModal;