
import { Box } from '@mui/system';
import type React from 'react';

const EngagerLogo: React.FC<{ maxHeight: number }> = ({ maxHeight }) => (
    <Box>
        <a href="https://engager.app" target="_blank" rel="noreferrer"><img alt="Engager.app logo" src="/img/logo-horizontal.svg" style={{ maxHeight }} /></a>
    </Box>
)

export default EngagerLogo;