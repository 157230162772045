import { Box } from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useCallback, useMemo } from "react";

const FormGeneratorDateTimeField: React.FC<{ withTime: boolean, rawValue: string | null, onPropagateValueChange: (newValue: string | null) => any }> = ({ withTime, rawValue, onPropagateValueChange }) => {

    const value = useMemo(() => {
        let value: dayjs.Dayjs | null = null;
        if (rawValue) {
            value = dayjs(rawValue);
            console.log("Curr date:", value.toDate(), "/ ISO string:", value.toISOString())
        }
        return value;
    }, [rawValue]);

    const onValueChange = useCallback((newValue: dayjs.Dayjs | null) => {
        if (newValue === null) {
            onPropagateValueChange(null);
            return;
        }
        try{
            onPropagateValueChange(newValue.toISOString())
            console.log("New date:", newValue.toDate(), "/ ISO string:", newValue.toISOString())
        } catch (err) {
            console.error("Cannot save form generator date time value:", err);
        }
    }, [onPropagateValueChange]);

    return <Box>
        {withTime ? <DateTimePicker
            timezone="UTC"
            value={value}
            onChange={onValueChange}
        /> : <DatePicker
            timezone="UTC"
            value={value}
            onChange={onValueChange}
        />}
    </Box>
}

export default FormGeneratorDateTimeField;