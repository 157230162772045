import { useMemo, useState } from "react";
import { sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";
import { Service } from "../../dataDefinitions/service";

export const useServices = () => {
  const [servicesRS, setServicesRS] = useState<RequestState<Service[]>>({ pending: false, error: '', data: null })
  
  const servicesApi = useMemo(() => ({ 
    getServices: async (clientId: number) => {
      await sendRequestUseRS(setServicesRS, 'GET', `/service/forClient/${clientId}`);
    },
  }), []);

  return { servicesRS, servicesApi }
}