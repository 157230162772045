import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { red, green } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import type React from 'react';

const PasswordStrengthList: React.FC<{ password: string }> = ({ password }) => {
    
    const [isProperLength, setIsProperLength] = useState(false);
    const [hasSmallChars, setHasSmallChars] = useState(false);
    const [hasCaps, setHasCaps] = useState(false);
    const [hasDigits, setHasDigits] = useState(false);
    const [hasSpecialChars, setHasSpecialChars] = useState(false);
    
    const checkStrength = useCallback((password: string) => {
        setIsProperLength(password.length >= 10);
        setHasSmallChars(Boolean(password.match(/[a-z]/g)));
        setHasCaps(Boolean(password.match(/[A-Z]/g)));
        setHasDigits(Boolean(password.match(/\d/g)));
        setHasSpecialChars(Boolean(password.match(/\W/g)));
    }, []);

    useEffect(() => {
        checkStrength(password)
    }, [checkStrength, password]);

    return <List dense>
        <ListItem sx={{color: isProperLength ? green[600] : red[600]}}>
            <ListItemIcon sx={{color:'inherit'}}>
                <FontAwesomeIcon icon={isProperLength ? faCheck : faTimes} />
            </ListItemIcon>
            <ListItemText primary="Has at least 10 characters"></ListItemText>
        </ListItem>
        <ListItem sx={{color: hasSmallChars ? green[600] : red[600]}}>
            <ListItemIcon sx={{color:'inherit'}}>
                <FontAwesomeIcon icon={hasSmallChars ? faCheck : faTimes} />
            </ListItemIcon>
            <ListItemText primary="Has at least 1 lowercase letter"></ListItemText>
        </ListItem>
        <ListItem sx={{color: hasCaps ? green[600] : red[600]}}>
            <ListItemIcon sx={{color:'inherit'}}>
                <FontAwesomeIcon icon={hasCaps ? faCheck : faTimes} />
            </ListItemIcon>
            <ListItemText primary="Has at least 1 uppercase letter"></ListItemText>
        </ListItem>
        <ListItem sx={{color: hasDigits ? green[600] : red[600]}}>
            <ListItemIcon sx={{color:'inherit'}}>
                <FontAwesomeIcon icon={hasDigits ? faCheck : faTimes} />
            </ListItemIcon>
            <ListItemText primary="Has at least 1 digit"></ListItemText>
        </ListItem>
        <ListItem sx={{color: hasSpecialChars ? green[600] : red[600]}}>
            <ListItemIcon sx={{color:'inherit'}}>
                <FontAwesomeIcon icon={hasSpecialChars ? faCheck : faTimes} />
            </ListItemIcon>
            <ListItemText primary="Has at least 1 special character"></ListItemText>
        </ListItem>
    </List>
}

export default PasswordStrengthList;