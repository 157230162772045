import Typography from '@mui/material/Typography'
import { useAccountant } from '../lib/hooks/extData/accountant';


const DashboardView: React.FC = () => {
  const { branding: accBranding } = useAccountant();
  return <div>
    <Typography variant="h4" sx={{mb:2}}>Welcome to the Client Portal for {accBranding?.Name}</Typography>
    <Typography variant="body1">Click a button on the left panel to go to Portal sections for different clients you are associated with.</Typography>
  </div>
}

export default DashboardView;