import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const YesNoDialog: React.FC<{ isOpen: boolean, title: string, message: string, yesLabel?: string, noLabel?: string, yesAction: () => any, noAction: () => any }> = ({
    isOpen,
    title,
    message,
    yesLabel = 'yes',
    noLabel = 'no',
    yesAction,
    noAction,
}) => {

    return <Dialog
        open={isOpen}
        onClose={() => noAction()}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color="success" onClick={() => yesAction()}>{yesLabel}</Button>
            <Button color="error" onClick={() => noAction()}>{noLabel}</Button>
        </DialogActions>
    </Dialog>
}

export default YesNoDialog;