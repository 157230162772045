import { DataFormDef, DataFormQnsWithInvalidOptions } from "../../dataDefinitions/formDef";

export const getFormDefWithNonexistentOptions = (formDef: DataFormDef, qnsWithInvalidOptions: DataFormQnsWithInvalidOptions, isInReadOnlyMode: boolean) => {
    const formDefAdjusted = {
        ...formDef,
        sections: (formDef.sections || []).map(section => ({ // create a new sections object to extend options for fields with invalid choices
            ...section,
            questions: (section.questions || []).map(qn => {
                if ((qn.type === 'select' || qn.type === 'radios' || qn.type === 'checkboxes') && qnsWithInvalidOptions[qn.varName]) {
                    const extendedOptions = [
                        {
                            label: `${isInReadOnlyMode ? '' : 'OPTION NOT ALLOWED: '}${qnsWithInvalidOptions[qn.varName]}`,
                            value: '#INVALID#',
                            isDisabled: true,
                        },
                        ...qn.specifics.options
                    ];
                    return {
                        ...qn,
                        specifics: {
                            ...qn.specifics,
                            options: extendedOptions
                        }
                    }
                } else {
                    return qn;
                }
            })
        }))
    }
    return formDefAdjusted;
}