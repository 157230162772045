import { useMemo, useState } from "react";
import { sendRequest, sendRequestUseRS } from "../../apiClient";
import { FsElemOrigin, FsElem, fsElemAdapter, FsRawElem, FsActualElemType, FileToUpload, FileUploadStatus, Breadcrumb } from "../../dataDefinitions/fsElem";
import { RequestState } from "../../dataDefinitions/request";
import axios from "axios";

interface FolderAndChildrenResp { actualDir: FsRawElem | null, templateDir: FsRawElem | null, children: FsRawElem[] }
interface FolderAndChildrenData { actualDir: FsElem | null, templateDir: FsElem | null, children: FsElem[] }

export const useFsElem = () => {
  const [folderAndChildrenRS, setFolderChildrenRS] = useState<RequestState<FolderAndChildrenData>>({ pending: false, error: '', data: null })
  const [breadcrumbsRS, setBreadcrumbsRS] = useState<RequestState<Breadcrumb[]>>({ pending: false, error: '', data: null });

  const [renameElemRS, setRenameElemRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })

  const [createFolderRS, setCreateFolderRS] = useState<RequestState<{ id: number }>>({ pending: false, error: '' })

  const [downloadElemRS, setDownloadElemRS] = useState<RequestState<null>>({ pending: false, error: '', data: null })
  const [previewElemRS, setPreviewElemRS] = useState<RequestState<{ url: string } | null>>({ pending: false, error: '', data: null })

  const [deleteElemRS, setDeleteElemRS] = useState<RequestState<null>>({ pending: false, error: '', data: null })

  const fsActualElemApi = useMemo(() => ({
    getFolderAndChildren: async (clientId: number, idType: FsElemOrigin, id: number | null) => {
      const dataPipe: Function[] = [
        (resp: FolderAndChildrenResp) => ({
          actualDir: resp.actualDir ? fsElemAdapter(resp.actualDir) : null,
          templateDir: resp.templateDir ? fsElemAdapter(resp.templateDir) : null,
          children: resp.children.map(elem => fsElemAdapter(elem))
        }),
      ];
      return await sendRequestUseRS(setFolderChildrenRS, 'GET', `/fsActualElem/dirAndChildren/${clientId}/${idType}/${id}`, { dataPipe });
    },
    getBreadcrumbs: async (clientId: number, dirIdType: FsElemOrigin, dirId: number | null) => {
      return await sendRequestUseRS(setBreadcrumbsRS, 'GET', `/fsActualElem/pathElemsUpToRoot/${clientId}/${dirIdType}/${dirId}`);
    },
    createFolder: async (clientId: number, parentIdType: FsElemOrigin, parentId: number | null, name: string) => {
      return await sendRequestUseRS(setCreateFolderRS, 'POST', `/fsActualElem/${clientId}/${parentIdType}/${parentId}`, {
        body: {
          name,
          type: FsActualElemType.DIR
        }
      });
    },
    deleteElem: async (id: number) => {
      return await sendRequestUseRS(setDeleteElemRS, 'DELETE', `/fsActualElem/${id}`);
    },
    renameElem: async (id: number, name: string) => {
      return await sendRequestUseRS(setRenameElemRS, 'PATCH', `/fsActualElem/rename/${id}`, { body: { name } });
    },
    downloadElem: async (id: number, filename: string, forPreview: boolean) => {
      return await sendRequestUseRS(forPreview ? setPreviewElemRS : setDownloadElemRS, 'GET', `/fsActualElem/downloadLink/${id}`, {
        dataPipe: [async (link: string) => {
          const response = await axios(link, { responseType: 'blob' });
          const url = URL.createObjectURL(response.data)
  
          if (!forPreview) {
            const aHref = document.createElement('a');
            aHref.href = url;
            aHref.target = '_blank';
            aHref.download = filename;
            aHref.click();
  
            URL.revokeObjectURL(url);
          }
          return forPreview ? { url } : null;
        }]
      });
    },
    uploadFile: async (clientId: number, parentIdType: FsElemOrigin, parentId: number, fileToUpload: FileToUpload, uploadCb: (indexToUpdate: number, status: FileUploadStatus, progress: number, error?: string) => void) => {
      const { size: size_bytes, type: content_type } = fileToUpload.fileElem;
      const { name, fileIndex } = fileToUpload;
  
      // Ack upload started
      uploadCb(fileIndex, FileUploadStatus.SENDING, 0)
  
      // Read file contents
      let fileBytes: ArrayBuffer;
      try {
        fileBytes = await fileToUpload.fileElem.arrayBuffer();
      } catch (err) {
        console.error("Error reading file", err)
        uploadCb(fileIndex, FileUploadStatus.ERROR, 0, "Unable to read file")
        return;
      }
  
      // Register a file
      const { data: postData, error: postError } = await sendRequest('POST', `/fsActualElem/${clientId}/${parentIdType}/${parentId}`, {
        body: {
          name,
          type: FsActualElemType.FIL,
          size_bytes,
          content_type
        }
      });
      if (postError) {
        uploadCb(fileIndex, FileUploadStatus.ERROR, 0, postError)
        return false;
      }
  
      // Get upload link
      const { data: uploadLinkData, error: uploadLinkError } = await sendRequest('GET', `/fsActualElem/uploadLink/${postData.id}`);
      if (uploadLinkError) {
        uploadCb(fileIndex, FileUploadStatus.ERROR, 0, uploadLinkError)
        return false;
      }
  
      // Upload to S3
      try {
        await axios.put(uploadLinkData, fileBytes, {
          headers: {
            'Content-Type': content_type
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
            uploadCb(fileIndex, FileUploadStatus.SENDING, percentCompleted)
          }
        })
      } catch (err) {
        console.error("Error sending to S3 in getUploadFile", err);
        // Delete not uploaded elem
        const { error: deleteError } = await sendRequest('DELETE', `/fsActualElem/${postData.id}`);
        if (deleteError) {
          uploadCb(fileIndex, FileUploadStatus.ERROR, 0, "Error sending file to the cloud and deleting its definition")
          return false;
        }
        uploadCb(fileIndex, FileUploadStatus.ERROR, 0, "Error sending file to the cloud")
        return false;
      }
  
      // Ack upload
      const { error: ackUploadError } = await sendRequest('PATCH', `/fsActualElem/ackUpload/${postData.id}`, {
        body: {
          upload_date: new Date()
        }
      });
      if (ackUploadError) {
        uploadCb(fileIndex, FileUploadStatus.ERROR, 0, ackUploadError)
        return false;
      }
      uploadCb(fileIndex, FileUploadStatus.SUCCESS, 100)
      return true;
    },
  }), []);

  return { folderAndChildrenRS, breadcrumbsRS, downloadElemRS, previewElemRS, createFolderRS, deleteElemRS, renameElemRS, fsActualElemApi };
}