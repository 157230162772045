import { faCheck, faDownload, faInputText, faSpinnerThird, faTimes, faTrashAlt, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Button, TextField } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { formatSize, FsActualElemType, FsElem, FsElemCreateSide, FsElemOrigin } from '../../lib/dataDefinitions/fsElem';
import { useFsElem } from '../../lib/hooks/extData/fsElem';
import GenericModal from '../../components/GenericModal';
import InfoElem from '../../components/InfoElem';
import { isMobile } from 'react-device-detect';
import useFileDownload from '../../lib/hooks/fileDownload';

const buttonMargin = { mr: 1, mb: 1 };

const FsElemDetails: React.FC<{ fsElem: FsElem, onClose: Function }> = ({ fsElem, onClose }) => {
  const { downloadElemRS, previewElemRS, deleteElemRS, renameElemRS, fsActualElemApi } = useFsElem();
  const { previewFile } = useFileDownload(previewElemRS.data?.url || '', fsElem.name);
  const [deleteConfVisible, setDeleteConfVisible] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [renameDialogVisible, setRenameDialogVisible] = useState(false);
  const [newElemName, setNewElemName] = useState('');

  const formatSizeCb = useCallback((size: number) => formatSize(size), []);

  const deleteFSelem = async () => {
    setDeleteConfVisible(false);
    const success = await fsActualElemApi.deleteElem(fsElem.id);
    if (success) {
      // Go to parent if deleting a folder
      onClose('DELETE');
    }
  }

  const renameElem = async () => {
    const success = await fsActualElemApi.renameElem(fsElem.id, newElemName);
    if (success) {
      setRenameDialogVisible(false);
      onClose('RENAME');
    }
  }

  const openPreview = useCallback(async () => {
    if (!previewElemRS.data?.url || isMobile) {
      // for mobile devices, preview is done using redirection to object URL
      // so after coming back and restoring JS state, the URL is no longer valid
      // thus has to be downloaded and created again
      await fsActualElemApi.downloadElem(fsElem.id, fsElem.name, true);
    }
    setPreviewOpen(true);
  }, [fsActualElemApi, fsElem, previewElemRS]);

  useEffect(() => {
    // For mobile devices when preview should be shown
    // close the preview popup and go to object URL instead
    if (previewOpen && isMobile) {
      setPreviewOpen(false);
      previewFile();
    }
  }, [previewOpen, previewFile])

  useEffect(() => {
    return () => {
      if (previewElemRS.data?.url) {
        URL.revokeObjectURL(previewElemRS.data.url);
      }
    }
  }, [previewElemRS])


  return <Box>
    <InfoElem label='Name' value={fsElem.name} />
    {fsElem.type === FsActualElemType.FIL && <>
      <InfoElem label='Size' value={formatSizeCb(fsElem.size_bytes)} />
      <InfoElem label='Uploaded' value={!fsElem.upload_date ? '(not uploaded)' : <><FormattedDate value={fsElem.upload_date} /> <FormattedTime value={fsElem.upload_date} /></>} />
    </>}
    {fsElem.type !== FsActualElemType.FIL && <>
      <InfoElem label='Created' value={<><FormattedDate value={fsElem.create_date} /> <FormattedTime value={fsElem.create_date} /></>} />
    </>}
    <InfoElem label='Created by' value={fsElem.create_side === FsElemCreateSide.CUS ? 'Client' : 'Accountant'} />
    {(previewElemRS.error || downloadElemRS.error || deleteElemRS.error) && <Alert sx={{ mb: 1 }} severity='error'>{previewElemRS.error || downloadElemRS.error || deleteElemRS.error}</Alert>}
    <Box>
      {fsElem.client_perms[0] === '1' && fsElem.type === FsActualElemType.FIL &&
        (fsElem.content_type === 'application/pdf' || fsElem.content_type.startsWith('image/')) &&
        <Button variant="outlined" color="primary" sx={buttonMargin}
          onClick={() => openPreview()}
          disabled={previewElemRS.pending}
          startIcon={<FontAwesomeIcon spin={previewElemRS.pending} icon={previewElemRS.pending ? faSpinnerThird : faEye} />}>
          Preview
        </Button>}
      {fsElem.client_perms[0] === '1' && fsElem.type === FsActualElemType.FIL && <Button variant="outlined" color="primary" sx={buttonMargin}
        onClick={() => fsActualElemApi.downloadElem(fsElem.id, fsElem.name, false)}
        disabled={downloadElemRS.pending}
        startIcon={<FontAwesomeIcon spin={downloadElemRS.pending} icon={downloadElemRS.pending ? faSpinnerThird : faDownload} />}>
        Download
      </Button>}
      {fsElem.client_perms[1] === '1' && fsElem.fsElemOrigin === FsElemOrigin.ACT && !fsElem.fs_template_elem_id && fsElem.create_side === FsElemCreateSide.CUS &&
        <Button variant="outlined" color="primary" sx={buttonMargin}
          onClick={() => { setNewElemName(fsElem.name); setRenameDialogVisible(true) }}
          startIcon={<FontAwesomeIcon icon={faInputText} />}>
          Rename
        </Button>}
      {false && fsElem.client_perms[1] === '1' && fsElem.create_side === FsElemCreateSide.CUS &&
        <Button variant="outlined" color="error"
          disabled={deleteElemRS.pending}
          startIcon={<FontAwesomeIcon spin={deleteElemRS.pending} icon={deleteElemRS.pending ? faSpinnerThird : faTrashAlt} />}
          onClick={() => setDeleteConfVisible(true)}>
          Delete
        </Button>}
    </Box>
    {fsElem.fsElemOrigin === FsElemOrigin.ACT && fsElem.type === FsActualElemType.FIL &&
      <GenericModal title="File preview" open={Boolean(previewElemRS.data?.url) && previewOpen} onClose={() => setPreviewOpen(false)} style={{ width: '100%', maxWidth: 1200 }} showCloseBtn={true}>
        <Box>
          {fsElem.content_type === 'application/pdf' && <embed src={previewElemRS.data?.url} type="application/pdf" style={{ width: '100%', height: 'calc(100vh - 150px)' }}></embed>}
          {fsElem.content_type.startsWith('image/') && <img alt="Content preview" src={previewElemRS.data?.url} style={{ maxWidth: '100%', maxHeight: 'calc(100vh - 150px)' }} />}
        </Box>
      </GenericModal>}
    <GenericModal title="Are you sure to delete this element?" open={deleteConfVisible} onClose={() => setDeleteConfVisible(false)}>
      <Box textAlign="center">
        <Button variant="outlined" color="success" sx={{ mr: 1 }} onClick={() => deleteFSelem()} startIcon={<FontAwesomeIcon icon={faCheck} />}>
          Yes
        </Button>
        <Button variant="outlined" color="error" onClick={() => setDeleteConfVisible(false)} startIcon={<FontAwesomeIcon icon={faTimes} />}>
          No
        </Button>
      </Box>
    </GenericModal>
    <GenericModal title="Rename element" open={renameDialogVisible} onClose={() => setRenameDialogVisible(false)}>
      {renameElemRS.error && <Alert severity='error' sx={{ mb: 2 }}>{renameElemRS.error}</Alert>}
      <TextField
        label="New name"
        value={newElemName}
        onChange={e => setNewElemName(e.target.value)}
        fullWidth={true}
        margin="normal"
      />
      <Box textAlign="center">
        <Button variant="outlined" color="success" sx={{ mr: 1 }}
          onClick={() => renameElem()}
          disabled={renameElemRS.pending}
          startIcon={<FontAwesomeIcon spin={renameElemRS.pending} icon={renameElemRS.pending ? faSpinnerThird : faCheck} />}>
          Confirm
        </Button>
        <Button variant="outlined" color="error" onClick={() => setRenameDialogVisible(false)} startIcon={<FontAwesomeIcon icon={faTimes} />}>
          Cancel
        </Button>
      </Box>
    </GenericModal>
  </Box>
}

export default FsElemDetails;