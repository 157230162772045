import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { sendRequestUseRS } from "../../apiClient";
import { loginScreenDetailsAtom } from "../../store/loginScreenDetails";


export const useLoginScreenDetails = () => {
    const [loginScreenDetailsRS, setLoginScreenDetailsRS] = useRecoilState(loginScreenDetailsAtom);

    const loginScreenDetailsApi = useMemo(() => ({
        getLoginScreenDetails: async () => {
            return await sendRequestUseRS(setLoginScreenDetailsRS, 'GET', `/loginScreenDetails/portal`);

        },
    }), [setLoginScreenDetailsRS]);

    return {loginScreenDetailsRS, loginScreenDetailsApi}
}