import { Reducer, useCallback, useReducer, useState } from 'react';
import GenericModal from '../../components/GenericModal';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Alert from '@mui/material/Alert'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { useFsElem } from '../../lib/hooks/extData/fsElem';
import { FileToUpload, FileUploadStatus, formatSize, FsElem, FsElemOrigin } from '../../lib/dataDefinitions/fsElem';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faClock, faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import TextField from '@mui/material/TextField'

const Dropzone = styled.div(props => ({
  borderRadius: 5,
  border: '1px dashed #888',
  padding: 10,
  textAlign: 'center',
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#EEE',
  cursor: 'pointer',
}))

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface UpdateStateAction {
  type: 'UPDATE_SINGLE',
  payload: {
    indexToUpdate: number, status: FileUploadStatus, progress: number, error?: string
  }
}
interface SetFilesToUpload {
  type: 'SET_ALL',
  payload: {
    files: FileToUpload[]
  }
}
interface RenameFileToUpload {
  type: 'RENAME_SINGLE',
  payload: {
    indexToUpdate: number,
    newName: string,
  }
}
interface UploadReducerState { files: FileToUpload[] }
type UploadReducerAction = SetFilesToUpload | UpdateStateAction | RenameFileToUpload

const uploadStateReducer: Reducer<UploadReducerState, UploadReducerAction> = (state, action) => {
  if (action.type === 'UPDATE_SINGLE') {
    const { indexToUpdate, status, progress, error } = action.payload;
    return {
      files: state.files.map((elem, index) => index !== indexToUpdate ? elem : {
        ...elem,
        status,
        progress,
        error
      })
    }
  } else if (action.type === 'SET_ALL') {
    return { files: action.payload.files }
  } else if (action.type === 'RENAME_SINGLE') {
    const { indexToUpdate, newName } = action.payload;
    return {
      files: state.files.map((elem, index) => index !== indexToUpdate ? elem : {
        ...elem,
        name: newName
      })
    }
  } else {
    return state;
  }
}

// onClose returns whether the creation was successful or not
const UploadFileModal: React.FC<{ clientId: number, currDir: FsElem | null, onClose: Function }> = ({ clientId, currDir, onClose }) => {
  const { fsActualElemApi } = useFsElem();
  const [globalFileUploadStatus, setGlobalFileUploadStatus] = useState(FileUploadStatus.QUEUED);
  const [renameState, setRenameState] = useState<{ indexToUpdate: number, newName: string } | null>(null);
  const [filesToUpload, dispatchUploadState] = useReducer(uploadStateReducer, { files: [] })

  const formatSizeCb = useCallback((size: number) => formatSize(size), []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!currDir) return;
    dispatchUploadState({
      type: 'SET_ALL',
      payload: {
        files: acceptedFiles.map((file: File, fileIndex: number) => ({
          status: FileUploadStatus.QUEUED,
          name: file.name,
          progress: 0,
          error: '',
          fileIndex,
          fileElem: file,
        }))
      }
    })
  }, [currDir])

  const startUploading = async () => {
    if (!currDir) return;
    setGlobalFileUploadStatus(FileUploadStatus.SENDING);
    // const allRes = await Promise.all(filesToUpload.filter(elem => elem.status !== FileUploadStatus.SUCCESS).map((fileToUpload, index) => fsActualElemApi.uploadFile(clientId, currDir.fsElemOrigin || FsElemOrigin.ACT, currDir.id, fileToUpload, index, uploadCb)))
    const allRes = [];
    for (const fileToUpload of filesToUpload.files.filter(elem => elem.status !== FileUploadStatus.SUCCESS)) {
      allRes.push(await fsActualElemApi.uploadFile(clientId, currDir.fsElemOrigin || FsElemOrigin.ACT, currDir.id, fileToUpload,
        (indexToUpdate: number, status: FileUploadStatus, progress: number, error?: string) => dispatchUploadState({ type: 'UPDATE_SINGLE', payload: { indexToUpdate, status, progress, error } })));
    }
    setGlobalFileUploadStatus(allRes.some(res => !res) ? FileUploadStatus.ERROR : FileUploadStatus.SUCCESS)
  }


  const startRenaming = (indexToUpdate: number, currName: string) => {
    setRenameState({ indexToUpdate, newName: currName });
  }
  const finishRenaming = (shouldSave: boolean) => {
    if (shouldSave && renameState && renameState.newName) {
      dispatchUploadState({ type: 'RENAME_SINGLE', payload: renameState })
    }
    setRenameState(null);
  }

  const { getRootProps, getInputProps } = useDropzone({ maxSize: 100 * 1024 ** 2, onDrop })

  return <GenericModal title="Upload files" open={true} onClose={() => onClose(false)}>
    {filesToUpload.files.length < 1 ? <Box>
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          <FontAwesomeIcon style={{ fontSize: 26 }} icon={faArrowDown} />
          <Typography variant='body1'><strong>Drag {'&'} drop</strong> files here <br />or <strong>click</strong> to select files</Typography>
          <Typography variant='body2'>max. size: 50 MB</Typography>
        </div>
      </Dropzone>
    </Box> : <Box>
      {filesToUpload.files.map((elem, index) => <Paper key={index} sx={{ mb: 2, p: 2 }}>
        {!renameState || renameState?.indexToUpdate !== index ? <>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ wordBreak: 'break-word' }}>{elem.name}</Typography>
            <Typography variant="body1" color="muted" sx={{ ml: 2, whiteSpace: 'nowrap' }}>{formatSizeCb(elem.fileElem.size)}</Typography>
          </Stack>
          {(elem.status === FileUploadStatus.QUEUED || elem.status === FileUploadStatus.ERROR) && <Button variant="text" color="primary" onClick={() => startRenaming(index, elem.name)}>
            rename
          </Button>}
        </> : <Stack direction="row">
          <TextField
            label="File name"
            value={renameState.newName}
            fullWidth={true}
            onChange={(e) => setRenameState({ ...renameState, newName: e.target.value })}
          />
          <IconButton sx={{ px: 2 }} size="small" onClick={() => finishRenaming(true)}><FontAwesomeIcon icon={faCheck} /></IconButton>
          <IconButton sx={{ px: 2 }} size="small" onClick={() => finishRenaming(false)}><FontAwesomeIcon icon={faTimes} /></IconButton>
        </Stack>}
        <Box sx={{ mt: 1 }}>
          {elem.status === FileUploadStatus.QUEUED && <Typography color="text.secondary"><FontAwesomeIcon icon={faClock} /> Queued</Typography>}
          {elem.status === FileUploadStatus.SENDING && <LinearProgressWithLabel variant="determinate" value={elem.progress} />}
          {elem.status === FileUploadStatus.ERROR && <Alert severity="error">{elem.error}</Alert>}
          {elem.status === FileUploadStatus.SUCCESS && <Alert severity="success">Upload successful</Alert>}
        </Box>
      </Paper>)}
    </Box>}
    {(globalFileUploadStatus === FileUploadStatus.QUEUED || globalFileUploadStatus === FileUploadStatus.ERROR) && <Box sx={{ mt: 2, textAlign: 'center' }}>
      {Boolean(filesToUpload.files.length) && <Button variant="outlined" color="success" sx={{ mr: 1 }} onClick={() => startUploading()}>
        {globalFileUploadStatus === FileUploadStatus.QUEUED ? 'Upload' : 'Retry failed uploads'}
      </Button>}
      <Button variant="outlined" color="error" onClick={() => onClose(globalFileUploadStatus === FileUploadStatus.ERROR)}>
        {globalFileUploadStatus === FileUploadStatus.QUEUED ? 'Cancel' : 'Skip failed & finish'}
      </Button>
    </Box>}
    {globalFileUploadStatus === FileUploadStatus.SUCCESS && <Box sx={{ textAlign: 'center' }}>
      <Button variant="outlined" color="success" onClick={() => onClose(true)}>
        Finish
      </Button>
    </Box>}
  </GenericModal>

}

export default UploadFileModal;