import { NBoolean } from "./simpleDataTypes"

export enum ClientStatus {
  PROSPECT = 'prospect',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum ClientType {
  LTD = 'Limited Company',
  LLP = 'Limited Liability Partnership',
  PAR = 'Partnership',
  IST = 'Individual or Sole Trader',
  CON = 'Contact Only',
  CHA = 'Charity',
  TRU = 'Trust',
}

export interface LinkedClient {
  id: number,
  name: string,
  type: ClientType,
  company_status: ClientStatus,
  contact_level: 0 | 1 | 2,
}

export interface Client {
  id: number,
  name: string,
  type: ClientType,
  company_status: ClientStatus,
  first_name: string,
  preferred_name: string,
  last_name: string,
  email: string,
  mobile: string,
  telephone: string,
  has_email: NBoolean,
  has_mobile: NBoolean,
  has_telephone: NBoolean,
  essential_email_allowed: NBoolean,
  marketing_email_allowed: NBoolean,
  essential_sms_allowed: NBoolean,
  marketing_sms_allowed: NBoolean,
}