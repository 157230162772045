import { Alert, AlertTitle, Button, TextField, Typography, Divider, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BoxedCircularProgress from '../components/BoxedCircularProgress';
import PasswordStrengthList from '../components/PasswordStrengthList';
import { useRegister } from '../lib/hooks/extData/register';
import { useCommonNavigation } from '../lib/hooks/commonNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

const RegisterView = () => {
  const { navigateToLogin } = useCommonNavigation();
  const [searchParams] = useSearchParams();
  const { registrationReqState, signupUidCheckRS, registerApi } = useRegister();

  const [uid, setUid] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatBlurred, setPasswordRepeatBlurred] = useState(false);

  const signUp = async () => {
    if (!uid) return;
    if (password !== passwordRepeat) return;
    await registerApi.register(uid, email, password);
  }

  const proceedToLogon = () => {
    navigateToLogin(email === 'NO-EMAIL' ? '' : email, { replace: true })
  }

  useEffect(() => {
    if (uid) {
      registerApi.checkSignupUid(uid);
    }
  }, [registerApi, uid])


  useEffect(() => {
    setUid(searchParams.get('uid') || 'NO-UID');
    setEmail(searchParams.get('email') || 'NO-EMAIL');
  }, [searchParams])

  const isLinkInvalid = uid === 'NO-UID' || email === 'NO-EMAIL' || !signupUidCheckRS.data?.isValid;

  return <>
    <Box sx={{ mb: 2, textAlign: 'center' }}>
      <Typography variant="h5">Sign up for Client Portal</Typography>
    </Box>
    {uid === null || email === null || signupUidCheckRS.pending
      ? <BoxedCircularProgress align="center" />
      : isLinkInvalid
        ? <Alert severity="error" sx={{ mt: 2 }}>
          <AlertTitle>Expired or invalid signup link</AlertTitle>
          <Typography variant='body2' sx={{ mb: 1 }}>If you already have an account, please click on the 'Log in' button below.</Typography>
          <Typography variant='body2'>Otherwise, request a new signup link from your accountant.</Typography>
        </Alert>
        : !registrationReqState.error && registrationReqState.data
          ? <Box>
            <Alert severity='success'>
              <AlertTitle>Success</AlertTitle>
              You have successfully signed up for Client Portal. Click a button below to proceed to logon screen.
            </Alert>
            <Box sx={{ textAlign: 'center', mt: 2 }}><Button color="primary" variant="contained" onClick={proceedToLogon}>Log in</Button></Box>
          </Box>
          : <Box>
            <Box mb={3}>
              <Typography variant="body2">E-mail address:</Typography>
              <Typography variant="body1">{email}</Typography>
            </Box>
            <Box mb={3}>
              <Typography>Password:</Typography>
              <TextField
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                autoComplete='new-password'
                fullWidth
                variant="outlined"
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faLockKeyhole} />
                    </InputAdornment>
                  ),
                }} />
              <PasswordStrengthList password={password} />
            </Box>
            <Box mb={3}>
              <Typography>Repeat password:</Typography>
              <TextField
                value={passwordRepeat}
                onChange={e => setPasswordRepeat(e.target.value)}
                onBlur={_ => setPasswordRepeatBlurred(true)}
                type="password"
                autoComplete='new-password'
                fullWidth
                variant="outlined"
                margin="dense"
                helperText={(passwordRepeatBlurred || passwordRepeat.length === password.length) && password !== passwordRepeat ? 'Passwords do not match' : ''}
                error={(passwordRepeatBlurred || passwordRepeat.length === password.length) && password !== passwordRepeat}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faLockKeyhole} />
                    </InputAdornment>
                  ),
                }} />
            </Box>

            {registrationReqState.error && <Alert sx={{ mb: 2 }} severity='error'>{registrationReqState.error}</Alert>}

            <Box sx={{ textAlign: 'center' }}>
              {registrationReqState.pending ? <BoxedCircularProgress align="center" /> : <Button variant="contained" color="success" onClick={signUp}>Sign up</Button>}
            </Box>
          </Box>}

    {!registrationReqState || registrationReqState.error || registrationReqState.data === null
      ? <Box>
        <Divider sx={{ my: 2 }}>{isLinkInvalid ? 'If' : 'or if'} you have already signed up:</Divider>
        <Box sx={{ textAlign: 'center' }}>
          <Button color="success" variant={isLinkInvalid ? 'contained' : 'outlined'} onClick={proceedToLogon}>Log in</Button>
        </Box>
      </Box>
      : ''}
  </>
}

export default RegisterView;