import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import type React from 'react';
import EngagerLogo from './EngagerLogo';

const PoweredByBox: React.FC<{maxLogoHeight?: number}> = ({ maxLogoHeight = 32 }) => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography>Powered by:</Typography>
        <EngagerLogo maxHeight={maxLogoHeight} />
    </Box>
)

export default PoweredByBox;