
import { Alert, AlertTitle, Box, CircularProgress, Divider, Link, Stack, Typography } from '@mui/material'
import EngagerLogo from './EngagerLogo'
import React from 'react'

const AppInitialBanner: React.FC<{ state?: 'LOADING' | 'ERROR', errorMessage?: string | null }> = ({ state = 'LOADING', errorMessage }) => {
    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ mb: 7 }}>
                <EngagerLogo maxHeight={60} />
            </Box>
            {state === 'LOADING'
                ? <Stack direction='row' gap={2} alignItems='center'>
                    <CircularProgress size={30} />
                    <Typography variant="body1">Loading...</Typography>
                </Stack>
                : <Alert severity='error' sx={{ maxWidth: 600 }}>
                    <AlertTitle>Unable to load the app</AlertTitle>
                    <Typography variant='body1'>Please check your network connection and reload the page to try again.</Typography>
                    <Typography variant='body1'>If the problem persists, contact support at: <Link href={
                        `mailto:contact@engager.app?subject=${encodeURIComponent(`Portal loading issues: ${window.location.hostname}`)
                        }&body=${!errorMessage ? '' : encodeURIComponent('Error details: \n' + errorMessage)}`
                    }>contact@engager.app</Link></Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant='body2'>Error details: {errorMessage || 'Unknown error'}</Typography>
                </Alert>}
        </Box>
    )
}

export default AppInitialBanner