import { faDownload, faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Alert, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { FormattedDate } from "react-intl";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import GenericModal from "../../components/GenericModal";
import InfoElem from "../../components/InfoElem";
import { SigningRequestExtended, SigningRequestStatus } from "../../lib/dataDefinitions/signingRequest";
import { useFsElem } from "../../lib/hooks/extData/fsElem";
import { useSigningRequest } from "../../lib/hooks/extData/signingRequest";
import useFileDownload from "../../lib/hooks/fileDownload";
const { REACT_APP_VERSION } = process.env;

const SigningFormModal: React.FC<{ request: SigningRequestExtended, onClose: Function, onParentReloadNeeded: Function }> = ({ request, onClose, onParentReloadNeeded }) => {
  const { previewElemRS, fsActualElemApi } = useFsElem();
  const { signRS, signingRequestApi } = useSigningRequest();
  const { downloadFile, previewFile } = useFileDownload(previewElemRS.data?.url || '', request.document_name);

  const [nextStatus, setNextStatus] = useState<SigningRequestStatus>(SigningRequestStatus.SIGNED);
  const [signeeName, setSigneeName] = useState<string>('');
  const [signeeComment, setSigneeComment] = useState<string>('');

  const submitSigning = useCallback(async () => {
    if (await signingRequestApi.sign(request.id, nextStatus, signeeName, signeeComment, REACT_APP_VERSION || '')) {
      onParentReloadNeeded();
      signingRequestApi.getPendingCountSummaryPerOwner();
    }
  }, [signingRequestApi, request, nextStatus, signeeName, signeeComment, onParentReloadNeeded]);

  const closeModal = useCallback(() => {
    if (previewElemRS.data?.url) {
      URL.revokeObjectURL(previewElemRS.data.url)
    }
    onClose()
  }, [previewElemRS, onClose])

  useEffect(() => {
    fsActualElemApi.downloadElem(request.doc_id, request.document_name, true);
  }, [fsActualElemApi, request])

  return <GenericModal title="Sign document" open={true} onClose={() => closeModal()} style={{ width: '100%', maxWidth: signRS.data ? 500 : 1300, maxHeight: 'calc(100vh - 20px)', overflowY: 'auto' }} showCloseBtn={true}>
    {signRS.data ? <Box>
      <Alert sx={{ mb: 1 }} severity="success">
        {nextStatus === SigningRequestStatus.SIGNED ? 'Document has been signed successfully' : 'Document rejection has been saved'}
      </Alert>
      <Stack direction="row" justifyContent="center"><Button variant="outlined" color="success" onClick={() => closeModal()}>finish</Button></Stack>
    </Box> :
      <Grid container spacing={2}>
        <Grid item xs={12} lg={true}>
          {previewElemRS.pending ? <BoxedCircularProgress /> :
            previewElemRS.error ? <Alert severity="error">{previewElemRS.errorCode === 403 ? 'You do not have access to this signing request' : previewElemRS.error}</Alert> :
              <Box>
                <BrowserView>
                  <Box>
                    {previewElemRS.data?.url ?
                      <embed src={previewElemRS.data?.url} type="application/pdf" style={{ width: '100%', height: 'calc(100vh - 180px)' }}></embed> :
                      <BoxedCircularProgress />}
                  </Box>
                </BrowserView>
                <Stack sx={{ mt: 1 }} spacing={1} direction="row" justifyContent="center">
                  <MobileView>
                    <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={faEye} />} onClick={previewFile}>
                      preview
                    </Button>
                  </MobileView>
                  <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={faDownload} />} onClick={downloadFile}>
                    download
                  </Button>
                </Stack>
              </Box>}
        </Grid>
        <Grid item xs={12} lg={4}>
          {signRS.pending ? <BoxedCircularProgress align="center" mb={1} message="Processing data..." /> :
            <Box>
              <InfoElem label="Document name" value={request.document_name} />
              <InfoElem label="Requested" value={<FormattedDate value={request.create_date} dateStyle="short" timeStyle="short" />} />
              {request.description && <InfoElem label="Request description" value={request.description} />}
              <Stack sx={{ mb: 2 }} direction="column" spacing={2}>
                <Box>
                  <FormControl>
                    <FormLabel id="signing-action-radio-group">Your action</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="signing-action-radio-group"
                      name="signing-action"
                      value={nextStatus}
                      onChange={e => { console.log(e.target.value); setNextStatus(e.target.value as SigningRequestStatus) }}
                    >
                      <FormControlLabel value={SigningRequestStatus.SIGNED} control={<Radio />} label="Sign" />
                      <FormControlLabel value={SigningRequestStatus.REJECTED} control={<Radio />} label="Reject" />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    id="signee-name"
                    label="Your full name"
                    value={signeeName}
                    onChange={e => setSigneeName(e.target.value)}
                    variant="outlined"
                    required={true}
                    inputProps={{ maxLength: 250 }}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    id="signee-comment"
                    label={nextStatus === SigningRequestStatus.SIGNED ? 'Comment (optional)' : 'Rejection reason'}
                    value={signeeComment}
                    onChange={e => setSigneeComment(e.target.value)}
                    variant="outlined"
                    inputProps={{ maxLength: 250 }}
                    required={nextStatus === SigningRequestStatus.REJECTED}
                  />
                </Box>
              </Stack>
              {signRS.error && <Alert sx={{ mb: 1 }} severity="error">{signRS.error}</Alert>}
              <Stack direction="row" justifyContent="center">
                <Button variant="outlined" color="success" onClick={submitSigning}>confirm</Button>
              </Stack>
            </Box>}
        </Grid>
      </Grid>}
  </GenericModal>
}

export default SigningFormModal;