import { atom } from "recoil";
import { Client } from "../dataDefinitions/client";
import { RequestState } from "../dataDefinitions/request";
import { User } from "../dataDefinitions/user";

export const currentUserAtom = atom<User | null>({
    key: 'currentUser',
    default: null
})

export const currentClientRSAtom = atom<RequestState<Client>>({
    key: 'currentClientRS',
    default: { pending: false, error: null }
})

export const userSettledAtom = atom<boolean>({
    key: 'userSettled',
    default: false
})