import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFileImage, faFileAudio, faFileVideo, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileAlt, faFileCode, faFileArchive } from "@fortawesome/pro-solid-svg-icons";

export enum FsElemOrigin {
  ACT = 'ACT', // actual elem
  TPL = 'TPL' // template elem
}

export enum FsActualElemType {
  FIL = 'FIL',
  DIR = 'DIR',
}

export enum FsElemCreateSide {
  CUS = 'CUS',
  ACC = 'ACC',
}

export interface Breadcrumb {
  label: string,
  id: number | null,
  idType: FsElemOrigin,
}

interface FsElemBase {
  id: number,
  parent_id: number,
  fs_template_elem_id: number,
  client_id: number,
  file_uid: string,
  type: FsActualElemType,
  name: string,
  size_bytes: number,
  content_type: string,
  client_perms: string,
  create_side: FsElemCreateSide,
  create_customer_user_id: number,
  create_user_id: number,
  fsElemOrigin?: FsElemOrigin,
}

export interface FsRawElem extends FsElemBase {
  upload_date: string,
  create_date: string,
  update_date: string,
}

export interface FsElem extends FsElemBase {
  upload_date: Date | null,
  create_date: Date,
  update_date: Date,
}

export enum FileUploadStatus {
  QUEUED,
  RENAMING,
  SENDING,
  SUCCESS,
  ERROR,
}

export interface FileToUpload {
  status: FileUploadStatus,
  name: string,
  progress: number,
  error?: string,
  fileIndex: number,
  fileElem: File,
};

export const fsElemAdapter = (raw: FsRawElem): FsElem => {
  return {
    ...raw,
    upload_date: raw.upload_date ? new Date(raw.upload_date) : null,
    create_date: new Date(raw.create_date),
    update_date: new Date(raw.update_date),
  }
}

export const formatSize = (size: number): string => {
  const prefixes = ['B', 'KB', 'MB', 'GB'];
  let output = "";
  for (const [index, prefix] of Object.entries(prefixes)) {
    const divSize = size / 1024 ** parseInt(index);
    if (divSize < 1) break;
    output = `${Math.round(divSize * 100) / 100} ${prefix}`;
  }
  return output;
};

export const iconClasses: { [key: string]: IconDefinition } = {
  // Media
  "image": faFileImage,
  "audio": faFileAudio,
  "video": faFileVideo,
  // Documents
  "application/pdf": faFilePdf,
  "application/msword": faFileWord,
  "application/vnd.ms-word": faFileWord,
  "application/vnd.oasis.opendocument.text": faFileWord,
  "application/vnd.openxmlformats-officedocument.wordprocessingml":
  faFileWord,
  "application/vnd.ms-excel": faFileExcel,
  "application/vnd.openxmlformats-officedocument.spreadsheetml":
  faFileExcel,
  "application/vnd.oasis.opendocument.spreadsheet": faFileExcel,
  "application/vnd.ms-powerpoint": faFilePowerpoint,
  "application/vnd.openxmlformats-officedocument.presentationml":
  faFilePowerpoint,
  "application/vnd.oasis.opendocument.presentation": faFilePowerpoint,
  "text/plain": faFileAlt,
  "text/html": faFileCode,
  "application/json": faFileCode,
  // Archives
  "application/gzip": faFileArchive,
  "application/zip": faFileArchive
};