import { useMemo, useCallback, useState } from "react"
import { useRecoilState } from "recoil";
import { getDefaultRSObject, sendRequest, sendRequestUseRS } from "../../apiClient";
import { LoginFormState } from "../../dataDefinitions/auth";
import { RequestState } from "../../dataDefinitions/request";
import { currentUserAtom, currentClientRSAtom, userSettledAtom } from "../../store/auth";

export const useAuth = () => {
    const [loggedInUser, setLoggedInUser] = useRecoilState(currentUserAtom);
    const [loggedInClientRS, setLoggedInClientRS] = useRecoilState(currentClientRSAtom);
    const [userSettled, setUserSettled] = useRecoilState(userSettledAtom);

    const [loginFormState, setLoginFormState] = useState<LoginFormState>(LoginFormState.CREDS);

    const [changePasswordRS, setChangePasswordRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })
    const [setup2faRS, setSetup2faRS] = useState<RequestState<{ otpUrl: string }>>({ pending: false, error: '', data: null })
    const [confirm2faRS, setConfirm2faRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })
    const [disable2faRS, setDisable2faRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })
    const [passwordResetStartRS, setPasswordResetStartRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })
    const [passwordResetFinishRS, setPasswordResetFinishRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })
    const [passwordResetCalloffRS, setPasswordResetCalloffRS] = useState<RequestState<'OK'>>({ pending: false, error: '', data: null })

    const refreshUser = useCallback(async () => {
        const { data, error } = await sendRequest('GET', '/auth/currentUser')
        if (error) throw error;

        localStorage.setItem('user', JSON.stringify(data));
        setLoggedInUser(data);
    }, [setLoggedInUser]);

    const authApi = useMemo(() => ({
        logIn: async (email: string, password: string) => {
            // Zero-ing logged in client RS to avoid login loop 
            // where 401 Unauthorized residual error 
            // caused by invalid token (expired or else) 
            // made LoggedInGrid log out immediately after showing up
            setLoggedInClientRS(getDefaultRSObject());
            setLoginFormState(LoginFormState.PROGRESS);
            const { data, error } = await sendRequest('POST', '/auth/logIn', {
                body: {
                    email,
                    password
                },
                includeToken: false,
            })
            if (error) {
                setLoginFormState(LoginFormState.CREDS);
                // Showing error should be handled in the form itself
                throw error;
            }
            const token: string = data.token;
            localStorage.setItem('token', token);
            if (data.token_type === 'intermediate') {
                console.log("Intermediate token")
                setLoginFormState(LoginFormState.TOTP_CODE);
            } else {
                console.log("Full token")
                refreshUser();
            }
        },
        logIn2fa: async (totpCode: string) => {
            setLoginFormState(LoginFormState.PROGRESS);
            const { data, error, errorCode } = await sendRequest('POST', '/auth/logIn2FA', {
                body: {
                    code: totpCode
                }
            })
            if (error) {
                console.error("TOTP login error:", error, errorCode)
                setLoginFormState(LoginFormState.CREDS);
                // Showing error should be handled in the form itself
                throw Error("Invalid code, try again")
            }
            const token: string = data.token;
            localStorage.setItem('token', token);
            refreshUser();
        },
        resetLoginForm: () => {
            setLoginFormState(LoginFormState.CREDS);
        },
        logOut: () => {
            sendRequest('POST', '/auth/logOut');

            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setLoggedInUser(null);
        },
        refreshUser,
        submitStartPasswordReset: async (email: string) => {
            return await sendRequestUseRS(setPasswordResetStartRS, 'POST', `/auth/startPasswordReset`, { body: { email } });
        },
        submitFinishPasswordReset: async (email: string, uid: string, newPassword: string) => {
            return await sendRequestUseRS(setPasswordResetFinishRS, 'POST', `/auth/finishPasswordReset`, { body: { email, uid, newPassword } });
        },
        submitCalloffPasswordReset: async (uid: string) => {
            return await sendRequestUseRS(setPasswordResetCalloffRS, 'POST', `/auth/calloffPasswordReset`, { body: { uid } });
        },
        getUser: () => {
            const userObjStr: string | null = localStorage.getItem('user');
            setLoggedInUser(userObjStr ? JSON.parse(userObjStr) : null);
            setUserSettled(true);
        },
        getLoggedInClient: (clientId: number) => {
            sendRequestUseRS(setLoggedInClientRS, 'GET', `/client/${clientId}`);
        },
        changePassword: async (currentPassword: string, password: string) => {
            return await sendRequestUseRS(setChangePasswordRS, 'PATCH', `/auth/changePassword`, { body: { currentPassword, password } });
        },
        setup2FA: async () => {
            sendRequestUseRS(setSetup2faRS, 'POST', `/auth/setup2FA`);
        },
        cancelSetup2FA: () => {
            setSetup2faRS({ pending: false, error: '', data: null })
        },
        confirm2FA: async (code: string) => {
            return await sendRequestUseRS(setConfirm2faRS, 'POST', `/auth/confirm2FA`, { body: { code } });
        },
        disable2FA: async () => {
            return await sendRequestUseRS(setDisable2faRS, 'POST', `/auth/disable2FA`);
        },
    }), [setLoggedInUser, setLoggedInClientRS, setUserSettled, setLoginFormState, refreshUser]); // 'set' functions will be created once, so memoized value will be re-created for each one only, not on every render

    return {
        loggedInUser, userSettled, loginFormState,
        loggedInClientRS,
        changePasswordRS,
        setup2faRS,
        confirm2faRS,
        disable2faRS,
        passwordResetStartRS, passwordResetFinishRS, passwordResetCalloffRS,
        authApi
    };
}