import { Alert, AlertTitle, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BoxedCircularProgress from '../../components/BoxedCircularProgress';
import PasswordStrengthList from '../../components/PasswordStrengthList';
import { useAuth } from '../../lib/hooks/extData/auth';
import { useCommonNavigation } from '../../lib/hooks/commonNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

const FinishPasswordResetView = () => {
  const { navigateToLogin } = useCommonNavigation();
  const [searchParams] = useSearchParams();
  const { passwordResetFinishRS, authApi } = useAuth();

  const [uid, setUid] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatBlurred, setPasswordRepeatBlurred] = useState(false);

  const submit = async () => {
    if (!uid) return;
    if (password !== passwordRepeat) return;
    await authApi.submitFinishPasswordReset(email, uid, password);
  }

  const proceedToLogon = () => {
    navigateToLogin(email === 'NO-EMAIL' ? '' : email, { replace: true })
  }

  useEffect(() => {
    setUid(searchParams.get('uid') || 'NO-UID');
    setEmail(searchParams.get('email') || 'NO-EMAIL');
  }, [searchParams])

  return <>
    <Box sx={{ mb: 2, textAlign: 'center' }}>
      <Typography variant="h5">Reset your password</Typography>
    </Box>
    {uid === null || email === null
      ? <BoxedCircularProgress align="center" />
      : uid === 'NO-UID' || email === 'NO-EMAIL'
        ? <Alert severity="error" sx={{ mt: 2 }}>
          <AlertTitle>Error</AlertTitle>Invalid password reset link.
        </Alert>
        : passwordResetFinishRS.pending
          ? <BoxedCircularProgress align="center" />
          : passwordResetFinishRS.data
            ? <Box>
              <Alert severity='success'>
                <AlertTitle>Success</AlertTitle>
                Your password has been reset successfully. Click the button below to proceed to the login screen.
              </Alert>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button color="success" variant="contained" onClick={proceedToLogon}>Log in</Button>
              </Box>
            </Box>
            : <Box>
              <Box mb={3}>
                <Typography variant="body2">E-mail address:</Typography>
                <Typography variant="body1">{email}</Typography>
              </Box>
              <Box mb={3}>
                <Box>
                  <Typography>New password:</Typography>
                  <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    autoComplete='new-password'
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faLockKeyhole} />
                        </InputAdornment>
                      ),
                    }} />
                </Box>
                <PasswordStrengthList password={password} />
              </Box>
              <Box mb={3}>
                <Typography>Repeat password:</Typography>
                <TextField
                  value={passwordRepeat}
                  onChange={e => setPasswordRepeat(e.target.value)}
                  onBlur={_ => setPasswordRepeatBlurred(true)}
                  type="password"
                  autoComplete='new-password'
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  helperText={(passwordRepeatBlurred || passwordRepeat.length === password.length) && password !== passwordRepeat ? 'Passwords do not match' : ''}
                  error={(passwordRepeatBlurred || passwordRepeat.length === password.length) && password !== passwordRepeat}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faLockKeyhole} />
                      </InputAdornment>
                    ),
                  }} />
              </Box>

              {passwordResetFinishRS.error && <Alert sx={{ mb: 2 }} severity='error'>{passwordResetFinishRS.error}</Alert>}

              <div className='login-form__actions'>
                <Button className='login-form__action--secondary' onClick={proceedToLogon} color="info" startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>Back to login</Button>
                <Button variant="contained" color="success" onClick={submit}>Confirm</Button>
              </div>
            </Box>}
  </>
}

export default FinishPasswordResetView;