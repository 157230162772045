import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/system/Box";

const BoxedCircularProgress: React.FC<{ align?: 'left' | 'center' | 'right', m?: number, mt?: number, mb?: number, message?: string }> = ({ align = 'center', m, mt, mb, message }) => <Box sx={{
  display: 'flex',
  alignItems: 'center', justifyContent: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center',
  m, mt, mb
}}>
  <CircularProgress />
  {message && <Box sx={{ml: 2}}>{message}</Box>}
</Box>

export default BoxedCircularProgress;