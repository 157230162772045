import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/extData/auth';
import BoxedCircularProgress from '../../components/BoxedCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { useCommonNavigation } from '../../lib/hooks/commonNavigation';

const CalloffPasswordResetView = () => {
  const [searchParams] = useSearchParams();
  const { navigateToLogin } = useCommonNavigation();
  const { passwordResetCalloffRS, authApi } = useAuth();

  const [uid, setUid] = useState('');

  useEffect(() => {
    setUid(searchParams.get('uid') || 'NO-UID');
  }, [searchParams])

  useEffect(() => {
    const submit = async () => {
      if (!uid || uid === 'NO-UID') return;
      await authApi.submitCalloffPasswordReset(uid);
    }
    submit();
  }, [uid, authApi])

  return <>
    <Box sx={{ mb: 2, textAlign: 'center' }}>
      <Typography variant="h5">Cancel password reset</Typography>
    </Box>
    {passwordResetCalloffRS.pending
      ? <BoxedCircularProgress align="center" />
      : <Box>
        {passwordResetCalloffRS.error
          ? <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {passwordResetCalloffRS.error}
          </Alert>
          : passwordResetCalloffRS.data
            ? <Alert severity='success'>
              <AlertTitle>Success</AlertTitle>
              Password reset procedure was cancelled and the reset link was invalidated.
            </Alert>
            : <Box>
              {uid === 'NO-UID' ? <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                Invalid link.
              </Alert> : <BoxedCircularProgress align="center" />}
            </Box>}
        <Box mt={2}>
          <div className='login-form__actions login-form__actions--centered'>
            <Button onClick={() => navigateToLogin()} color="info" startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>Back to login</Button>
          </div>
        </Box>
      </Box>}
  </>
}

export default CalloffPasswordResetView;