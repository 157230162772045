import axios from "axios";

import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { getDefaultRSObject, sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";
import { SigningRequestExtended, SigningRequestDownloadLink, SigningRequestStatus } from "../../dataDefinitions/signingRequest";
import { pendingCountSummaryPerOwnerRSatom } from "../../store/signingRequest";

const BASE_URL = '/signingRequest';

export const useSigningRequest = () => {
  const [pendingCountSummaryPerOwnerRS, setPendingCountSummaryPerOwnerRS] = useRecoilState(pendingCountSummaryPerOwnerRSatom);
  const [signingRequestsRS, setSigningRequestsRS] = useState<RequestState<SigningRequestExtended[]>>(getDefaultRSObject())
  const [signRS, setSignRS] = useState<RequestState<'OK'>>(getDefaultRSObject())

  const [downloadElemRS, setDownloadElemRS] = useState<RequestState<null>>(getDefaultRSObject())
  const [previewElemRS, setPreviewElemRS] = useState<RequestState<{ url: string, filename: string } | null>>(getDefaultRSObject())

  const signingRequestApi = useMemo(() => ({
    getPendingCountSummaryPerOwner: async () => {
      await sendRequestUseRS(setPendingCountSummaryPerOwnerRS, 'GET', `${BASE_URL}/pendingCountSummaryPerOwner/`);
    },
    getForDocOwner: async (ownerId: number) => {
      await sendRequestUseRS(setSigningRequestsRS, 'GET', `${BASE_URL}/forDocOwner/${ownerId}`);
    },
    sign: async (id: number, status: SigningRequestStatus, signee_provided_name: string, 
      signee_comment: string, portal_version: string) => {
      return await sendRequestUseRS(setSignRS, 'POST', `${BASE_URL}/sign/${id}`, {
        body: {
          status,
          signee_provided_name,
          signee_comment,
          portal_version,
        }
      });
    },
    downloadSigned: async (id: number, forPreview: boolean) => {
      return await sendRequestUseRS(forPreview ? setPreviewElemRS : setDownloadElemRS, 'GET', `${BASE_URL}/downloadLinkToSignedDoc/${id}`, {
        dataPipe: [async (output: SigningRequestDownloadLink) => {
          const response = await axios(output.link, { responseType: 'blob' });
          const url = URL.createObjectURL(response.data)
  
          if (!forPreview) {
            const aHref = document.createElement('a');
            aHref.href = url;
            aHref.target = '_blank';
            aHref.download = output.filename;
            aHref.click();
  
            URL.revokeObjectURL(url);
          }
          return forPreview ? { url, filename: output.filename } : null;
        }]
      });
    },
  }), [setPendingCountSummaryPerOwnerRS]);

  return {
    pendingCountSummaryPerOwnerRS, signingRequestsRS, signRS, downloadElemRS, previewElemRS,
    signingRequestApi
  }
}