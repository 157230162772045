import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { sendRequest } from "../../apiClient";
import { generalPortalSettings } from "../../store/settings";


export const useSettings = () => {
    const [generalSettings, setGeneralSettings] = useRecoilState(generalPortalSettings);

    const settingsApi = useMemo(() => ({
        getGeneralSettings: async () => {
            const { data } = await sendRequest('GET', '/settings/general');
            setGeneralSettings(data);
        }
    }), [setGeneralSettings]);

    return { generalSettings, settingsApi }
}