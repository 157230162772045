import { useMemo } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"

export const useCommonNavigation = () => {
    const navigate = useNavigate()

    const navApi = useMemo(() => ({
        navigateToLogin: (email?: string, navigateOptions?: NavigateOptions) => {
            navigate(`/?email=${encodeURIComponent(email || '')}`, navigateOptions)
        }
    }), [navigate])

    return navApi
}