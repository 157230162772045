import { IconDefinition, faCheckCircle, faClock, faExclamationCircle, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { NBoolean } from "./simpleDataTypes"

export enum DataFormRequestState {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    AMEND_REQ = 'AMEND_REQ',
}

interface StatusReadableMapping { 
    label: string,
    icon: IconDefinition,
    color: 'warning' | 'success' | 'error'
}
export const DATA_FORM_REQUEST_STATE_MAPPING: {
    [DataFormRequestState.PENDING]: StatusReadableMapping, 
    [DataFormRequestState.IN_PROGRESS]: StatusReadableMapping, 
    [DataFormRequestState.AMEND_REQ]: StatusReadableMapping, 
    [DataFormRequestState.FINISHED]: StatusReadableMapping, 
} = {
    [DataFormRequestState.PENDING]: {
        label: 'Pending',
        icon: faClock,
        color: 'warning'
    },
    [DataFormRequestState.IN_PROGRESS]: {
        label: 'In progress',
        icon: faSpinner,
        color: 'warning'
    },
    [DataFormRequestState.AMEND_REQ]: {
        label: 'Amendment requested',
        icon: faExclamationCircle,
        color: 'error'
    },
    [DataFormRequestState.FINISHED]: {
        label: 'Finished',
        icon: faCheckCircle,
        color: 'success'
    },
};

export interface DataFormRequest {
    id: number
    form_def_id: number
    client_id: number
    prepopulate_current_values: NBoolean,
    allow_multiple_runs: NBoolean,
    state: DataFormRequestState
    create_date: string
    create_user_id: number
    create_comment: string | null
    amend_req_date: Date | null
    amend_req_reason: string | null
    amend_req_user_id: number | null
    finish_date: string | null
    finish_comment: string | null
    finisher_name: string | null
}


export interface FormRequestCountSummaryPerClient {
    [clientId: string]: number // form request count
}
