import './App.css';
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import WelcomeLayout from './components/layout/WelcomeLayout/WelcomeLayout';
import LoggedInGrid from './components/layout/LoggedInGrid';
import Box from '@mui/material/Box';
import { useAuth } from './lib/hooks/extData/auth';
import { useAccountant } from './lib/hooks/extData/accountant';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import DashboardView from './uiFragments/Dashboard';
import ChangePasswordView from './uiFragments/ChangePassword';
import FilesView from './uiFragments/Files/Files';
import ClientDetailsView from './uiFragments/ClientDetails';
import LoginView from './uiFragments/Login';
import RegisterView from './uiFragments/Register';
import SetUp2FAView from './uiFragments/SetUp2FA';
import SigningRequestsView from './uiFragments/SigningRequests/SigningRequests';
import StartPasswordResetView from './uiFragments/PasswordReset/StartPasswordReset';
import FinishPasswordResetView from './uiFragments/PasswordReset/FinishPasswordReset';
import CalloffPasswordResetView from './uiFragments/PasswordReset/CalloffPasswordReset';
import LinkMobileAppView from './uiFragments/LinkMobileApp';
import ContactUsView from './uiFragments/ContactUs';
import FormRequestsView from './uiFragments/DataForms/FormRequests';
import QuickAppLinkView from './uiFragments/QuickAppLink';

import AppInitialBanner from './components/AppInitialBanner';

import { APP_QUICK_LINK_PATHNAME } from './lib/dataDefinitions/appLink';

const Home = () => {
  const ejsc = window.location.hostname;

  const { loggedInUser, userSettled, authApi } = useAuth();
  const { branding, accountantApi } = useAccountant();
  const [hexCheckingStatus, setHexCheckingStatus] = useState<boolean | null>(null);
  const [theme, setTheme] = useState<Theme>(createTheme({
    palette: {
      primary: {
        main: '#777777',
      },
    },
  }))

  useEffect(() => {
    if (!branding) {
      accountantApi.getBranding();
    }
  }, [branding, accountantApi])

  useEffect(() => {
    authApi.getUser()
  }, [authApi]);

  // Set document title and theme
  useEffect(() => {
    if (!branding) return;
    document.title = 'Client Portal - ' + branding.Name;
    const newTheme = createTheme({
      palette: {
        primary: {
          main: branding.DominantColor,
        },
      },
    });
    setTheme(newTheme);
  }, [branding])

  useEffect(() => {
    const navigateIfAddressEndsInDot = () => {
      if (window.location.hostname.endsWith('.')) {
        window.location.replace(`https://${window.location.hostname.slice(0, -1)}`);
      }
    }
    navigateIfAddressEndsInDot();
  }, [])

  useEffect(() => {
    const checkHash = async () => {
      const encoder = new TextEncoder();
      const ejscPart = ejsc.slice(-12);
      const data = encoder.encode(ejscPart);
      const digested = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(digested));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
      // console.log("Hex:", hashHex, ejscPart);
      setHexCheckingStatus(hashHex === '9c45a9394242cb2d485febde786a97ae5063483a9c6723d8499111facfefe91e');
    }
    checkHash();
  }, [ejsc])

  return (
    <ThemeProvider theme={theme}>
      <div>
        <main>
          {hexCheckingStatus === null
            ? <AppInitialBanner />
            : hexCheckingStatus === false
              ? <AppInitialBanner state='ERROR' errorMessage='Cannot load modules' />
              : <div>
                <Router>
                  {!loggedInUser
                    ? !userSettled
                      ? <AppInitialBanner />
                      : <WelcomeLayout>
                        <Routes>
                          <Route path="*" element={<LoginView />} />
                          <Route path={APP_QUICK_LINK_PATHNAME} element={<QuickAppLinkView />} />
                          <Route path="/startPasswordReset" element={<StartPasswordResetView />} />
                          <Route path="/finishPasswordReset" element={<FinishPasswordResetView />} />
                          <Route path="/calloffPasswordReset" element={<CalloffPasswordResetView />} />
                          <Route path="/register" element={<RegisterView />} />
                        </Routes>
                        <Box sx={{ position: 'fixed', bottom: '10px', right: '10px', fontSize: "10pt", color: '#FFFFFFAA' }}>
                          {process.env.REACT_APP_VERSION}
                        </Box>
                      </WelcomeLayout>
                    : <LoggedInGrid>
                      <Routes>
                        <Route path="/" element={<DashboardView />} />
                        <Route path="/account" element={<ChangePasswordView />} />
                        <Route path="/setUp2FA" element={<SetUp2FAView />} />
                        <Route path="/linkMobileApp" element={<LinkMobileAppView />} />
                        <Route path="/contactUs" element={<ContactUsView />} />
                        <Route path="/files/:clientId" element={<FilesView />} />
                        <Route path="/signingRequests/:clientId" element={<SigningRequestsView />} />
                        <Route path="/formRequests/:clientId" element={<FormRequestsView />} />
                        <Route path="/client/:clientId" element={<ClientDetailsView />} />
                        <Route path="*" element={<Navigate to={'/'} />} />
                      </Routes>
                    </LoggedInGrid>
                  }
                </Router>
              </div>}
        </main>
      </div>
    </ThemeProvider>
  )
}

export default Home;
