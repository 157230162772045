import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { FormGeneratorFieldOption } from "../../lib/dataDefinitions/formGenerator";

const FormGeneratorMultipleChoiceField: React.FC<{ options: FormGeneratorFieldOption[], currSelection: string[] | number[], onPropagateValueChange: (newValue: string[]) => any }> = ({ options, currSelection, onPropagateValueChange }) => {

    const onOptionToggle = (isChecked: boolean, optionValue: string) => {
        const newSelection = new Set<string>(currSelection.map(elem => elem.toString()));
        if(isChecked) {
            newSelection.add(optionValue);
        } else {
            newSelection.delete(optionValue);
        }
        onPropagateValueChange(Array.from(newSelection));
    }

    return <Box>
        <FormGroup>
            {options.map(option => <FormControlLabel key={option.value} control={<Checkbox 
                checked={currSelection.map(elem => elem.toString()).includes(option.value)}
                onChange={(e) => onOptionToggle(e.target.checked, option.value)}
            />} label={option.label} />)}
        </FormGroup>
    </Box>
}

export default FormGeneratorMultipleChoiceField;