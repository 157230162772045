import { DataFormDef, DataFormQnType, DataFormQnsWithInvalidOptions } from "../../dataDefinitions/formDef";
import { FormGeneratorRadiosField, FormGeneratorSelectField } from "../../dataDefinitions/formGenerator";
import { DataFormRunValues } from "../../dataDefinitions/formRun";
import { getEmptyAddressObj } from "../address";

const flattenToQns = (formDef: DataFormDef) => (formDef.sections || []).map(elem => elem.questions || []).flat();

const getChoiceQns = (formDef: DataFormDef) => {
    return flattenToQns(formDef)
        .filter(qn => qn.formQnType !== DataFormQnType.INDEP)
        .filter(qn => qn.type === 'select' || qn.type === 'radios') as (FormGeneratorSelectField | FormGeneratorRadiosField)[];
}

const mapChoiceLabelsAndValues = (inLabelsToValuesMode: boolean, values: DataFormRunValues, formDef: DataFormDef): DataFormRunValues => {
    if (!Object.keys(values).length) return {};
    const mappedValues = {
        ...values
    }
    const choiceQns = getChoiceQns(formDef);

    for (const qn of choiceQns) {
        const dictChoices = qn.specifics.options;
        // When a label does not have its corresponding value, set the value as '#INVALID#'.
        if (inLabelsToValuesMode) {
            if (mappedValues[qn.varName] !== null && mappedValues[qn.varName] !== undefined) {
                // currentValue must be a string because we only take into account BUILTIN and CFIELD questions and the value is not nullish
                const currentValue = mappedValues[qn.varName] as string;
                const option = dictChoices.find(choice => choice.label.toLowerCase().trim() === currentValue.toLowerCase().trim());
                // Map only when an option is found and its value along with it, otherwise replace value with #INVALID# symbol
                mappedValues[qn.varName] = option ? option.value : '#INVALID#';
            }
        } else {
            // current value might be numeric
            mappedValues[qn.varName] = (dictChoices.find(choice => choice.value === (mappedValues[qn.varName] || '').toString())?.label) || null;
        }
    }
    return mappedValues;
}

export const mapChoiceLabelsToValues = (values: DataFormRunValues, formDef: DataFormDef): DataFormRunValues => {
    return mapChoiceLabelsAndValues(true, values, formDef);
}

export const mapChoiceValuesToLabels = (values: DataFormRunValues, formDef: DataFormDef): DataFormRunValues => {
    return mapChoiceLabelsAndValues(false, values, formDef);
}

export const getEmptyFormRunValuesObj = (formDef: DataFormDef) => {
    const qns = flattenToQns(formDef);
    const values: { [varName: string]: any } = {};
    for (const qn of qns) {
        if (qn.type === 'address') {
            values[qn.varName] = getEmptyAddressObj();
        } else if (qn.type === 'checkboxes') {
            values[qn.varName] = [];
        } else {
            values[qn.varName] = null;
        }
    }
    return values;
}

export const getQuestionsWithInvalidOptions = (formDef: DataFormDef, formRunValues: DataFormRunValues): DataFormQnsWithInvalidOptions => {
    // Map question to its non-existent chosen option value
    const qnsWithInvalidOptions: DataFormQnsWithInvalidOptions = {};
    const qns = (formDef.sections || []).map(section => section.questions || []).flat();
    for (const qn of qns) {
        if (qn.formQnType !== DataFormQnType.INDEP && (qn.type === 'select' || qn.type === 'radios')) {
            if (formRunValues[qn.varName] !== null && formRunValues[qn.varName] !== undefined &&
                !qn.specifics.options.find(option => option.label.toLowerCase().trim() === (formRunValues[qn.varName] as string).toLowerCase().trim())) {
                qnsWithInvalidOptions[qn.varName] = formRunValues[qn.varName];
            }
        }
    }
    return qnsWithInvalidOptions;
}