
import axios from 'axios';
import { RequestState, SendReqOutput } from './dataDefinitions/request';

//// In case we switch to a different domain for backend than the frontend
// const subdomain = window.location.hostname.slice('portal.'.length, -1 * '.engager.app'.length);
// const apiHostname = `${subdomain}.api.engager.app`;
// export const BASE_URL = `https://${apiHostname}/portalApi`;

//// Portal and API in the same domain
export const BASE_URL = `/portalApi`;

export const getDefaultRSObject: () => RequestState<any> = () => {
    return { pending: false, error: '', data: null };
}

export const prepareRSfromSendReqOutput: (sendReqOutput: SendReqOutput<any>) => RequestState<any> = (sendReqOutput) => {
    const { data, error, errorCode } = sendReqOutput;
    if(error){
        return { pending: false, error: error?.toString() || 'Unknown error', errorCode, data: null }
    }
    return { pending: false, error, errorCode, data }
}

export const processReqError = (err: any): SendReqOutput<null> => {
    if (axios.isAxiosError(err)) {
        return {
            data: null,
            error: err.response?.status === 401 ? 'The session has ended. Please log out and back in.' : 
                (typeof err.response?.data !== 'string' || err.response.data === '') ? ('Unknown fetching error' + (err.response?.status ? ` - code: ${err.response.status}` : '')) : 
                err.response.data,
            errorCode: err.response?.status
        }
    } else {
        return {
            data: null,
            error: typeof err === 'string' ? err : 'Unknown error'
        }
    }
}

type SendRequestOptions = {
    queryParams?: { [key: string]: string },
    body?: any,
    unpackReturned?: boolean,
    includeToken?: boolean,
    dataPipe?: Function[],
}

export const sendRequest = async (method: string, endpoint: string, { queryParams, body, dataPipe = [], unpackReturned = true, includeToken = true }: SendRequestOptions = {}): Promise<SendReqOutput<any>> => {
    try {
        const resp = await axios({
            method,
            baseURL: BASE_URL,
            url: endpoint,
            headers: {
                'Authorization': !includeToken ? '' : 'Bearer ' + (localStorage.getItem('token') || ''),
            },
            params: queryParams,
            data: body,
            responseType: 'json'
        })
        let pipedData = unpackReturned ? resp.data['returned'] : resp.data;
        for(const pipeFunc of dataPipe){
            pipedData = await pipeFunc(pipedData);
        }
        return {
            data: pipedData,
            error: null,
        }
    } catch (err) {
        console.error("Error in sendRequest", endpoint, err);
        return processReqError(err);
    }
}

export const sendRequestUseRS = async (rsSetter: React.Dispatch<React.SetStateAction<RequestState<any>>>, method: string, endpoint: string, { queryParams, body, dataPipe = [], unpackReturned = true, includeToken = true }: SendRequestOptions = {}): Promise<boolean> => {
    rsSetter({ pending: true, error: '', data: null });
    const sendReqOutput = await sendRequest(
        method,
        endpoint,
        {
            queryParams,
            body,
            unpackReturned,
            includeToken,
            dataPipe,
        }
    );
    if (sendReqOutput.error) {
        rsSetter(prepareRSfromSendReqOutput(sendReqOutput));
        return false;
    }
    rsSetter(prepareRSfromSendReqOutput(sendReqOutput));
    return true;
}

export const sendGetBlobRequest = async (method: string, endpoint: string, { queryParams, body, dataPipe = [], includeToken = true }: SendRequestOptions = {}) => {
    try {
        const resp = await axios({
            method,
            baseURL: BASE_URL,
            url: endpoint,
            headers: {
                'Authorization': !includeToken ? '' : 'Bearer ' + (localStorage.getItem('token') || ''),
            },
            params: queryParams,
            responseType: 'blob'
        })
        return {
            data: resp.data,
            error: null,
        }
    } catch (err: any) {
        console.error("Error getting Blob from API", endpoint, err);
        return processReqError(err);
    }
}