import { FormGeneratorDataField } from "./formGenerator"
import { NBoolean } from "./simpleDataTypes"

export enum DataFormState {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    ARCHIVED = 'ARCHIVED',
}
export enum DataFormQnType {
    BUILTIN = 'BUILTIN',
    CFIELD = 'CFIELD',
    INDEP = 'INDEP'
}

export enum DataFormQnDataType {
    TEXT = 'text',
    LONGTEXT = 'longtext',
    DECIMAL = 'decimal',
    FLOAT = 'float',
    DATE = 'date',
    DATETIME = 'datetime',
    CHOICE = 'choice',
    MULTI_CHOICE = 'multi_choice',
    ADDRESS = 'address',
    FILES = 'files',
}

export interface DataFormDefSection {
    label: string
    description: string
    id: number
    form_def_id: number
    seq: number
    // questions might not always be attached here
    questions?: FormGeneratorDataFieldAsDataFormQn[]
}

export type FormGeneratorDataFieldAsDataFormQn = (FormGeneratorDataField & {
    formQnType: DataFormQnType
});

export interface DataFormQnsWithInvalidOptions {
    [qnVarName: string]: string
}

export interface DataFormDef {
    name: string
    description: string
    state: DataFormState
    is_open: NBoolean
    category: string | null
    id: number
    // sections might not be attached every time
    sections?: DataFormDefSection[]
}