import { Box, Grid, TextField } from "@mui/material";
import { Address, AddressKey } from "../../lib/dataDefinitions/address";

const FormGeneratorAddressField: React.FC<{ value: Address, onPropagateValueChange: (newValue: Address) => any }> = ({ value, onPropagateValueChange }) => {

    const onAddressPartChange = (partKey: AddressKey, newValue: string) => {
        onPropagateValueChange({
            ...value,
            [partKey]: newValue,
        })
    }

    return <Box>
        <Box sx={{ mb: 2 }}>
            <TextField
                fullWidth={true}
                label="Premise, Street Name"
                multiline={true}
                minRows={2}
                value={value.lines || ''}
                onChange={(e) => onAddressPartChange('lines', e.target.value)}
            />
        </Box>
        <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth={true}
                        label="City / Town"
                        value={value.city || ''}
                        onChange={(e) => onAddressPartChange('city', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth={true}
                        label="County / District"
                        value={value.country_subdivision || ''}
                        onChange={(e) => onAddressPartChange('country_subdivision', e.target.value)}
                    />
                </Grid>
            </Grid>
        </Box>
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth={true}
                        label="Postcode"
                        value={value.postal_code || ''}
                        onChange={(e) => onAddressPartChange('postal_code', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth={true}
                        label="Country"
                        value={value.country || ''}
                        onChange={(e) => onAddressPartChange('country', e.target.value)}
                    />
                </Grid>
            </Grid>
        </Box>
    </Box>
}

export default FormGeneratorAddressField;