import type React from 'react'
import { Box } from '@mui/material'
import PoweredByBox from '../../PoweredByBox';
import { useLoginScreenDetails } from '../../../lib/hooks/extData/loginScreenDetails';
import AppInitialBanner from '../../AppInitialBanner';
import { useCallback, useEffect, useMemo, useState } from 'react';

import './WelcomeLayout.css';
import { LoginScreenDetails } from '../../../lib/dataDefinitions/loginScreenDetails';
import { PracticeIdentityStyleForLogin } from '../../../lib/dataDefinitions/settings';

const WelcomeLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { loginScreenDetailsRS, loginScreenDetailsApi } = useLoginScreenDetails();
    const [currentEmoji, setCurrentEmoji] = useState<string | null>(null)

    const details = loginScreenDetailsRS.data;

    const wrapperBackground = useMemo<string>(() => {
        if (!details) {
            return '#AAA';
        }
        return !details.loginScreenSettings.backgroundGradientCol || !details.loginScreenSettings.backgroundGradientCol.length
            ? '#AAAAAA'
            : details.loginScreenSettings.backgroundGradientCol.length === 1
                ? details.loginScreenSettings.backgroundGradientCol[0]
                : `linear-gradient(${details.loginScreenSettings.backgroundGradientDeg}deg, ${details.loginScreenSettings.backgroundGradientCol.join(', ')})`
    }, [details]);

    const populateCurrentEmoji = useCallback((details: LoginScreenDetails) => {
        const lastEmojiIndex: number = parseInt(localStorage.getItem('app_currentLoginEmojiIndex') || '') || 0;
        console.log("Last emoji index:", lastEmojiIndex)
        const emojis = details.loginScreenSettings.emojis;
        const emojiIndexToUse = (lastEmojiIndex + 1) % emojis.length;
        if (isNaN(emojiIndexToUse)) {
            setCurrentEmoji(null);
        }
        setCurrentEmoji(emojis[emojiIndexToUse]);
        localStorage.setItem('app_currentLoginEmojiIndex', emojiIndexToUse.toString());
        console.log("Setting emoji:", emojiIndexToUse)
    }, []);

    useEffect(() => {
        if (!details || !details.loginScreenSettings.useEmojis || currentEmoji) {
            return;
        }
        populateCurrentEmoji(details);
    }, [details, currentEmoji, populateCurrentEmoji])

    useEffect(() => {
        if (!loginScreenDetailsRS.pending
            && !loginScreenDetailsRS.data
            && !loginScreenDetailsRS.error) {
            loginScreenDetailsApi.getLoginScreenDetails();
        }
    }, [loginScreenDetailsRS, loginScreenDetailsApi])

    return !details
        ? <AppInitialBanner state={loginScreenDetailsRS.pending ? 'LOADING' : 'ERROR'} errorMessage={loginScreenDetailsRS.error} />
        : <Box>
            <div id="login-wrapper" style={{ background: wrapperBackground }}>
                <div id="login-container">
                    <div id="login-form-container">
                        <div id="login-form" className="login-form">
                            <div id="login-form__header">
                                {currentEmoji
                                    && <div className="login-form__welcome-icon">
                                        {currentEmoji}</div>}
                                <h1>Welcome to your<br />Client Portal</h1>
                            </div>
                            <div>{children}</div>
                            {details.loginScreenSettings.practiceIdentityStyle !== PracticeIdentityStyleForLogin.NONE
                                && <div className="powered-by-container">
                                    <PoweredByBox />
                                </div>
                            }
                        </div>
                    </div>
                    <div id="login-hero-container">
                        <div id="login-hero">
                            {details.loginScreenSettings.practiceIdentityStyle === PracticeIdentityStyleForLogin.NONE
                                && <div>
                                    <img src="/img/engager-logo-dark-stack-rgb.svg" id="engager-logo-for-no-branding" alt="Engager Logo" />
                                </div>}
                            {details.loginScreenSettings.practiceIdentityStyle === PracticeIdentityStyleForLogin.NAME
                                || (details.loginScreenSettings.practiceIdentityStyle === PracticeIdentityStyleForLogin.LOGO
                                    && !details.companyDetails.Logo)
                                ? <div id="login-hero-practice-name">
                                    {details.companyDetails.Name}
                                </div>
                                : ''}
                            {details.loginScreenSettings.practiceIdentityStyle === PracticeIdentityStyleForLogin.LOGO
                                && details.companyDetails.Logo
                                ? <div>
                                    <img src="/portalApi/assets/logo-full.png" alt="Logo" />
                                </div>
                                : ''}
                            {details.loginScreenSettings.practiceIdentityStyle !== PracticeIdentityStyleForLogin.NONE
                                && <div className="powered-by-container">
                                    <PoweredByBox maxLogoHeight={40} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Box>
}

export default WelcomeLayout;