import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode';

const QrTable = styled.table(() => ({
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
  border: 'none',
}))

const QrCell = styled.td(() => ({
  width: 5,
  height: 5,
  backgroundColor: '#FFF',
}))

const FilledQrCell = styled(QrCell)(() => ({
  backgroundColor: '#000',
}))

const QrCode: React.FC<{ textToEncode: string }> = ({ textToEncode }) => {

  const [cells, setCells] = useState<number[][]>([]);

  useEffect(() => {
    console.log("Text changed", textToEncode)
    if (textToEncode) {
      const code = QRCode.create(textToEncode, {});
      const newCells: number[][] = [];
      const cellCount = code.modules.data.length;
      const width = code.modules.size;
      for (let rowInd = 0; rowInd < cellCount / width; rowInd++) {
        newCells.push([...code.modules.data.slice(rowInd * width, (rowInd + 1) * width)])
      }
      setCells(newCells)
    }
  }, [textToEncode])


  return (
    <div>
      <QrTable>
        <tbody>
          {cells.map((row, rowIndex) => <tr key={'row' + rowIndex}>
            {row.map((cell, cellIndex) => cell !== 0 ?
              <FilledQrCell key={'filled-cell' + ((cellIndex+1) * (rowIndex+1))} /> :
              <QrCell key={'empty-cell' + ((cellIndex+1) * (rowIndex+1))} />)}
          </tr>)}
        </tbody>
      </QrTable>
    </div>
  )
}

export default QrCode;