import React, { useEffect } from 'react'
import { useAccountant } from '../lib/hooks/extData/accountant'
import BoxedCircularProgress from '../components/BoxedCircularProgress';
import { Alert, Typography, Box, Stack, Button, Paper, SxProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope, faGlobe, faLocationDot, faMobileAlt, faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { isMobile } from 'react-device-detect';

const CONTACT_STACK_STYLE = {
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
}
const CONTACT_INFO_STYLE: React.CSSProperties = {
    overflowWrap: 'break-word',
}
const PAPER_SX: SxProps = {
    p: 2,
    width: '100%',
    maxWidth: '350px',
}
const ICON_STYLE: React.CSSProperties = {
    fontSize: '2em',
    marginBottom: '.2em',
    color: '#0000008a'
}
const ICON_AND_INFO_BOX_STYLE: React.CSSProperties = {
    textAlign: 'center',
    marginBottom: '1em',
}

const ContactUsView = () => {
    const { accountantApi, branding, contactInfoRS } = useAccountant();

    useEffect(() => {
        accountantApi.getContactInfo();
    }, [accountantApi])

    return <div>
        {contactInfoRS.pending ? <BoxedCircularProgress /> :
            contactInfoRS.error ? <Alert severity='error'>Error loading contact information: {contactInfoRS.error}</Alert> :
                !branding ? <Alert severity='error'>Error getting branding information</Alert> :
                    <div>
                        <Typography variant="h4" sx={{ mb: 2 }}>{branding.Name}</Typography>
                        <Box>
                            {!contactInfoRS.data?.Address && !contactInfoRS.data?.Website && !contactInfoRS.data?.Email && !contactInfoRS.data?.BookingCalendarLink && !contactInfoRS.data?.Mobile && !contactInfoRS.data?.Telephone &&
                                <Box>(no contact information available)</Box>}
                            {contactInfoRS.data?.Address && <Box sx={{ mb: 3 }}>
                                <Typography variant="h5" sx={{ mb: 1 }}>Our office</Typography>
                                {contactInfoRS.data?.Address && <Stack direction={'row'} sx={{ gap: 1 }}>
                                    <FontAwesomeIcon icon={faLocationDot} style={{ paddingTop: 5, fontSize: '1.2em' }}></FontAwesomeIcon>
                                    <Box>
                                        {contactInfoRS.data.Address.split('\n').map((line, lineIndex) => <Typography key={lineIndex}>{line}</Typography>)}
                                    </Box>
                                </Stack>}
                            </Box>}
                            {(contactInfoRS.data?.Website || contactInfoRS.data?.Email || contactInfoRS.data?.BookingCalendarLink) && <Box sx={{ mb: 3 }}>
                                <Typography variant="h5" sx={{ mb: 1 }}>Contact us online</Typography>
                                <Stack direction={'row'} sx={{ gap: '10px', flexWrap: 'wrap' }}>
                                    {contactInfoRS.data?.Website && <Paper sx={PAPER_SX}>
                                        <Stack style={CONTACT_STACK_STYLE}>
                                            <Box style={ICON_AND_INFO_BOX_STYLE}>
                                                <FontAwesomeIcon icon={faGlobe} style={ICON_STYLE}></FontAwesomeIcon>
                                                <Typography style={CONTACT_INFO_STYLE}>{contactInfoRS.data.Website}</Typography>
                                            </Box>
                                            <Button variant="outlined" color="primary" target='_blank' href={contactInfoRS.data.Website.startsWith('http') ? contactInfoRS.data.Website : 'http://' + contactInfoRS.data.Website}>
                                                visit our website
                                            </Button>
                                        </Stack>
                                    </Paper>}
                                    {contactInfoRS.data?.Email && <Paper sx={PAPER_SX}>
                                        <Stack style={CONTACT_STACK_STYLE}>
                                            <Box style={ICON_AND_INFO_BOX_STYLE}>
                                                <FontAwesomeIcon icon={faEnvelope} style={ICON_STYLE}></FontAwesomeIcon>
                                                <Typography style={CONTACT_INFO_STYLE}>{contactInfoRS.data.Email}</Typography>
                                            </Box>
                                            <Button variant="outlined" color="primary" target='_blank' href={'mailto:' + contactInfoRS.data.Email}>
                                                send us an e-mail
                                            </Button>
                                        </Stack>
                                    </Paper>}
                                    {contactInfoRS.data?.BookingCalendarLink && <Paper sx={PAPER_SX}>
                                        <Stack style={CONTACT_STACK_STYLE}>
                                            <Box style={ICON_AND_INFO_BOX_STYLE}>
                                                <FontAwesomeIcon icon={faCalendarAlt} style={ICON_STYLE}></FontAwesomeIcon>
                                                <Typography style={CONTACT_INFO_STYLE}>Online calendar</Typography>
                                            </Box>
                                            <Button variant="outlined" color="primary" target='_blank' href={contactInfoRS.data.BookingCalendarLink}>
                                                Book a meeting with us
                                            </Button>
                                        </Stack>
                                    </Paper>}
                                </Stack>
                            </Box>}
                            {(contactInfoRS.data?.Mobile || contactInfoRS.data?.Telephone) && <Box sx={{ mb: 3 }}>
                                <Typography variant="h5" sx={{ mb: 1 }}>Contact us over the phone</Typography>
                                <Stack direction={'row'} sx={{ gap: '10px', flexWrap: 'wrap' }}>
                                    {contactInfoRS.data?.Mobile && <Paper sx={PAPER_SX}>
                                        <Stack style={CONTACT_STACK_STYLE}>
                                            <Box style={ICON_AND_INFO_BOX_STYLE}>
                                                <FontAwesomeIcon icon={faMobileAlt} style={ICON_STYLE}></FontAwesomeIcon>
                                                <Typography style={CONTACT_INFO_STYLE}>{contactInfoRS.data.Mobile}</Typography>
                                            </Box>
                                            {isMobile ?
                                                <Button variant="outlined" color="primary" href={'tel:' + contactInfoRS.data.Mobile}>
                                                    call our mobile number
                                                </Button> :
                                                <Typography>Mobile number</Typography>}
                                        </Stack>
                                    </Paper>}
                                    {contactInfoRS.data?.Telephone && <Paper sx={PAPER_SX}>
                                        <Stack style={CONTACT_STACK_STYLE}>
                                            <Box style={ICON_AND_INFO_BOX_STYLE}>
                                                <FontAwesomeIcon icon={faPhoneVolume} style={ICON_STYLE}></FontAwesomeIcon>
                                                <Typography style={CONTACT_INFO_STYLE}>{contactInfoRS.data.Telephone}</Typography>
                                            </Box>
                                            {isMobile ?
                                                <Button variant="outlined" color="primary" href={'tel:' + contactInfoRS.data.Telephone}>
                                                    call our landline number
                                                </Button> :
                                                <Typography>Landline number</Typography>}
                                        </Stack>
                                    </Paper>}
                                </Stack>
                            </Box>}
                        </Box>
                    </div>}
    </div>
}

export default ContactUsView