import { faCheck, faClock, faTimes, IconDefinition } from "@fortawesome/pro-solid-svg-icons"

export enum SigningRequestStatus {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
}

interface StatusReadableMapping { 
  label: string, 
  icon: IconDefinition, 
  color: 'warning' | 'success' | 'error' 
}
export const SIGNING_REQUEST_STATUS_READABLE_MAPPING: { 
  PENDING: StatusReadableMapping,
  SIGNED: StatusReadableMapping
  REJECTED: StatusReadableMapping 
} = {
  [SigningRequestStatus.PENDING]: { label: 'Pending', icon: faClock, color: 'warning' },
  [SigningRequestStatus.SIGNED]: { label: 'Signed', icon: faCheck, color: 'success' },
  [SigningRequestStatus.REJECTED]: { label: 'Rejected', icon: faTimes, color: 'error' },
}

export enum SigningRequestListMode {
  FS_ELEM_DETAILS = 'FS_ELEM_DETAILS',
  FULL_VIEW = 'FULL_VIEW',
}

export interface SigningRequest {
  id: number,
  doc_id: number,
  signee_client_id: number,
  create_date: Date,
  create_user_id: number,
  finish_date: Date | null,
  status: SigningRequestStatus,
  description: string | null,
  signee_provided_name: string | null,
  signee_comment: string | null,
  signee_ip: string | null,
  signee_user_agent: string | null,
  signed_file_uid: string | null,
}

export interface SigningRequestExtended extends SigningRequest {
  signee_name: string,
  document_name: string,
}

export interface SigningRequestCountSummaryPerOwner {
  [ownerId: string]: number // signing request count
}

export interface SigningRequestDownloadLink {
  link: string,
  filename: string,
}