import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import BoxedCircularProgress from "../components/BoxedCircularProgress";
import { APP_QUICK_LINK_HREF } from "../lib/dataDefinitions/appLink";

enum MobileAppLinkingFlowStep {
    OPENING,
    OPENED,
}

const APP_OPEN_TIMEOUT_MS = 5 * 1000; // sometimes it takes a while to load the redirected page, hence 5 seconds

const QuickAppLinkView = () => {
    const [searchParams] = useSearchParams();
    const [flowStep, setFlowStep] = useState<MobileAppLinkingFlowStep>(MobileAppLinkingFlowStep.OPENING);

    useEffect(() => {
        let noRedirectParam = searchParams.get('noRedirect');
        if (noRedirectParam !== '1') {
            window.location.href = APP_QUICK_LINK_HREF;
        }
    }, [searchParams]);

    useEffect(() => {
        if (flowStep === MobileAppLinkingFlowStep.OPENING) {
            setTimeout(() => {
                setFlowStep(MobileAppLinkingFlowStep.OPENED);
            }, APP_OPEN_TIMEOUT_MS);
        }
    }, [flowStep])

    return <Box sx={{ mb: 1, textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Link the mobile app</Typography>
        {flowStep === MobileAppLinkingFlowStep.OPENING && <Box>
            <BoxedCircularProgress message="Opening the app..." />
        </Box>}
        {flowStep === MobileAppLinkingFlowStep.OPENED && <Box>
            <Alert severity="error" sx={{ mb: 2, textAlign: 'left' }}>
                <Typography variant="body2" sx={{ mb: 1 }}>If the app was installed correctly, it should already be open at this point. If that is not the case, please go back to the login screen and try linking the app again.</Typography>
                <Typography variant="body2">If the problem persists, please try an alternative linking method or contact support using buttons below.</Typography>
            </Alert>
            <Box sx={{ mb: 2 }}>
                <Button href="https://engager.app/app-connection-guide/" variant="contained">
                    Try an alternative linking method
                </Button>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Button href={`mailto:contact@engager.app?subject=App linking issue: ${window.location.hostname}`} variant="text">
                    Contact support
                </Button>
            </Box>
            <Box>
                <Link to="/">
                    <Button variant="text" startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>Back to login</Button>
                </Link>
            </Box>
        </Box>}
    </Box>
}

export default QuickAppLinkView;