import './App.css';
import { RecoilRoot } from 'recoil';
import { IntlProvider } from 'react-intl';
import Home from './Home';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function App() {
  return (
    <RecoilRoot>
      <IntlProvider locale='en-GB'>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
          <Home />
        </LocalizationProvider>
      </IntlProvider>
    </RecoilRoot>
  )
}

export default App;
