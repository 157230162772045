import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, Select, MenuItem, Grid, FormControl, InputLabel, Card, CardContent, Stack, Chip, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedDate } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import InfoElem from "../../components/InfoElem";
import { SigningRequestExtended, SigningRequestStatus, SIGNING_REQUEST_STATUS_READABLE_MAPPING } from "../../lib/dataDefinitions/signingRequest";
import { useClient } from "../../lib/hooks/extData/clients";
import { useSigningRequest } from "../../lib/hooks/extData/signingRequest";
import OriginalDocumentPreviewModal from "./OriginalDocumentPreviewModal";
import SignedDocumentPreviewModal from "./SignedDocumentPreviewModal";
import SigningFormModal from "./SigningFormModal";

const CardInfoElem: React.FC<{ label: string, value: any }> = ({ label, value }) => <InfoElem label={label} value={value} sx={{ mb: 1 }} />

type StatusFilterState = SigningRequestStatus | 'all';

const SigningRequestsView: React.FC = () => {
  const { clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { clientRS, clientApi } = useClient();
  const { signingRequestsRS, signingRequestApi } = useSigningRequest();

  const [statusFilter, setStatusFilter] = useState<StatusFilterState>(SigningRequestStatus.PENDING);
  const [signingRequests, setSigningRequests] = useState<SigningRequestExtended[]>([]);

  const [requestToPreviewSignedDoc, setRequestToPreviewSignedDoc] = useState<SigningRequestExtended | null>(null);
  const [requestToPreviewOriginalDoc, setRequestToPreviewOriginalDoc] = useState<SigningRequestExtended | null>(null);
  const [requestToSign, setRequestToSign] = useState<SigningRequestExtended | null>(null);

  const previewSignedRequest = useCallback((request: SigningRequestExtended) => {
    if (request.status !== SigningRequestStatus.SIGNED) {
      console.error("Trying to preview unsigned request");
      return;
    }
    setRequestToPreviewSignedDoc(request);
  }, [])

  const previewRejectedRequest = useCallback((request: SigningRequestExtended) => {
    if (request.status !== SigningRequestStatus.REJECTED) {
      console.error("Trying to preview unrejected request");
      return;
    }
    setRequestToPreviewOriginalDoc(request);
  }, [])

  const startSigningRequest = useCallback((request: SigningRequestExtended) => {
    if (request.status !== SigningRequestStatus.PENDING) {
      console.error("Trying to sign request that is not pending");
      return;
    }
    setRequestToSign(request);
  }, [])

  const filterRequests = useCallback(() => {
    const unfiltered = signingRequestsRS.data || [];
    setSigningRequests(unfiltered.filter(elem => (
      (statusFilter === 'all' || statusFilter === elem.status)
    )))
  }, [signingRequestsRS, statusFilter])

  const changeStatusFilter = useCallback((newValue: StatusFilterState) => {
    setSearchParams({ status: newValue })
  }, [setSearchParams])

  const getSigningRequests = useCallback(() => {
    if (!clientId || isNaN(parseInt(clientId))) return;
    signingRequestApi.getForDocOwner(parseInt(clientId));
  }, [clientId, signingRequestApi]);

  useEffect(() => {
    let statusParam = searchParams.get('status');
    if (statusParam) {
      setStatusFilter(statusParam as StatusFilterState)
    }
  }, [searchParams])

  useEffect(() => {
    const requestToSign = signingRequests.find(elem => (
      elem.id === parseInt(searchParams.get('requestToSignId') || '')
    ));
    if (requestToSign) {
      startSigningRequest(requestToSign);
    }
  }, [searchParams, signingRequests, startSigningRequest])

  useEffect(() => filterRequests(), [
    filterRequests,
    signingRequestsRS,
    statusFilter
  ])

  // Get initial data
  useEffect(() => {
    if (!clientId || isNaN(parseInt(clientId))) return;
    clientApi.getClient(parseInt(clientId));
    getSigningRequests();
  }, [clientId, clientApi, getSigningRequests])

  return clientRS.pending ? <BoxedCircularProgress align="center" mt={2} /> :
    clientRS.error || !clientRS.data ? <Typography color="error">{clientRS.error || 'Data not loaded'}</Typography> : <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" mb={1}>Signing requests</Typography>
        <Typography variant="body1">{clientRS.data.name}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="status-filter-label">Filter by status</InputLabel>
              <Select
                labelId="status-filter-label"
                label="Filter by status"
                value={statusFilter}
                onChange={(e) => changeStatusFilter(e.target.value as StatusFilterState)}
              >
                <MenuItem value={'all'}>all statuses</MenuItem>
                <MenuItem value={SigningRequestStatus.PENDING}>{SIGNING_REQUEST_STATUS_READABLE_MAPPING[SigningRequestStatus.PENDING].label}</MenuItem>
                <MenuItem value={SigningRequestStatus.SIGNED}>{SIGNING_REQUEST_STATUS_READABLE_MAPPING[SigningRequestStatus.SIGNED].label}</MenuItem>
                <MenuItem value={SigningRequestStatus.REJECTED}>{SIGNING_REQUEST_STATUS_READABLE_MAPPING[SigningRequestStatus.REJECTED].label}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md>
            <Typography variant="body1"><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> Showing newest requests first.</Typography>
          </Grid>
        </Grid>
      </Box>
      {signingRequestsRS.pending ? <BoxedCircularProgress align="center" mt={2} /> :
        signingRequestsRS.error || !signingRequestsRS.data ? <Typography color="error">{signingRequestsRS.error || 'Data not loaded'}</Typography> : <Box>
          {!signingRequests.length ? <Typography>(no requests to show)</Typography> : <Grid container spacing={2} alignItems="stretch">
            {signingRequests.map((request) => <Grid item key={request.id} xs={12} md={6} lg={4} xl={3}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ height: '100%' }}>
                  <Stack sx={{ height: '100%' }} justifyContent='space-between'>
                    <Box>
                      <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100vw - 80px)' }} variant="h6" color="initial">{request.document_name}</Typography>
                      <Stack sx={{ mb: 1 }} direction="row" alignItems="baseline">
                        <Chip
                          icon={<Box sx={{ pl: 1 }}>
                            <FontAwesomeIcon icon={SIGNING_REQUEST_STATUS_READABLE_MAPPING[request.status].icon} />
                          </Box>}
                          label={SIGNING_REQUEST_STATUS_READABLE_MAPPING[request.status].label}
                          color={SIGNING_REQUEST_STATUS_READABLE_MAPPING[request.status].color}
                        />
                        {request.finish_date && <Typography sx={{ ml: 1 }} variant="body1" color="initial">on: <FormattedDate value={request.finish_date} dateStyle="short" timeStyle="short" /></Typography>}
                      </Stack>
                      <CardInfoElem label="Requested" value={<FormattedDate value={request.create_date} dateStyle="short" timeStyle="short" />} />
                      {request.description && <CardInfoElem label="Request description" value={request.description} />}
                      {request.signee_comment && <CardInfoElem label={request.status === SigningRequestStatus.SIGNED ? 'Signee comment' : 'Rejection reason'} value={request.signee_comment} />}
                    </Box>
                    <Box>
                      {request.status === SigningRequestStatus.PENDING ? <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        <Button variant="outlined" color="primary" onClick={() => startSigningRequest(request)}>sign or reject</Button>
                      </Stack> : request.status === SigningRequestStatus.SIGNED ? <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        <Button variant="outlined" color="primary" onClick={() => previewSignedRequest(request)}>view signed document</Button>
                      </Stack> : <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        <Button variant="outlined" color="primary" onClick={() => previewRejectedRequest(request)}>view original document</Button>
                      </Stack>}
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>)}
          </Grid>}
        </Box>}
      {requestToPreviewSignedDoc && <SignedDocumentPreviewModal
        request={requestToPreviewSignedDoc}
        onClose={() => setRequestToPreviewSignedDoc(null)}
      />}
      {requestToPreviewOriginalDoc && <OriginalDocumentPreviewModal
        request={requestToPreviewOriginalDoc}
        onClose={() => setRequestToPreviewOriginalDoc(null)}
      />}
      {requestToSign && <SigningFormModal
        request={requestToSign}
        onClose={() => setRequestToSign(null)}
        onParentReloadNeeded={() => getSigningRequests()}
      />}
    </Box>
}

export default SigningRequestsView;