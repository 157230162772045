import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import TextField from '@mui/material/TextField'
import { useState } from "react";
import PasswordStrengthList from "../components/PasswordStrengthList";
import { useAuth } from "../lib/hooks/extData/auth";
import BoxedCircularProgress from "../components/BoxedCircularProgress";
import Button from '@mui/material/Button'

const ChangePasswordView = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordRepeatBlurred, setPasswordRepeatBlurred] = useState(false);
  const { changePasswordRS, authApi } = useAuth();

  const inputSx = {
    display: 'block',
  }

  const submitPasswordChange = () => {
    if (password !== passwordRepeat) return;
    authApi.changePassword(currentPassword, password);
  }

  return <Box>
    <Typography variant="h4" sx={{ mb: 3 }}>Change password</Typography>
    {changePasswordRS.pending ? <BoxedCircularProgress align="left" /> :
      changePasswordRS.data ? <Alert sx={{ mb: 2 }} severity="success">Password changed successfully</Alert> :
        <Box sx={{
          width: '100%',
          maxWidth: 500
        }}>
          {changePasswordRS.error && <Alert sx={{ mb: 2 }} severity="error">{changePasswordRS.error}</Alert>}
          <TextField
            sx={inputSx}
            margin="normal"
            fullWidth={true}
            type="password"
            label="Current password"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
          />
          <TextField
            sx={inputSx}
            margin="normal"
            fullWidth={true}
            type="password"
            label="New password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <PasswordStrengthList password={password} />
          <TextField
            sx={inputSx}
            margin="normal"
            fullWidth={true}
            type="password"
            label="Repeat password"
            value={passwordRepeat}
            onChange={e => setPasswordRepeat(e.target.value)}
            onBlur={() => setPasswordRepeatBlurred(true)}
            helperText={(passwordRepeatBlurred || passwordRepeat.length === password.length) && password !== passwordRepeat ? 'Passwords do not match' : ''}
            error={(passwordRepeatBlurred || passwordRepeat.length === password.length) && password !== passwordRepeat}
          />
          <Button sx={{ mt: 2 }} variant="contained" onClick={() => submitPasswordChange()}>
            Confirm
          </Button>
        </Box>}
  </Box>
}

export default ChangePasswordView;