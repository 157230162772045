import { Alert, AlertTitle, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/extData/auth';
import BoxedCircularProgress from '../../components/BoxedCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { useCommonNavigation } from '../../lib/hooks/commonNavigation';

const StartPasswordResetView = () => {
  const [searchParams] = useSearchParams();
  const { navigateToLogin } = useCommonNavigation();
  const { passwordResetStartRS, authApi } = useAuth();

  const [email, setEmail] = useState("");

  const submit = async () => {
    if (!email) return;
    await authApi.submitStartPasswordReset(email);
  }

  useEffect(() => {
    setEmail(searchParams.get('email') || '');
  }, [searchParams])

  return <>
    <Box sx={{ mb: 2, textAlign: 'center' }}>
      <Typography variant="h5">Reset your password</Typography>
    </Box>
    {passwordResetStartRS.pending
      ? <BoxedCircularProgress align="center" />
      : passwordResetStartRS.error
        ? <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {passwordResetStartRS.error}
        </Alert>
        : passwordResetStartRS.data
          ? <Alert severity='success'>
            <AlertTitle>Success</AlertTitle>
            If the email address provided corresponds to an existing Portal account, the password reset link will arrive shortly. Please check the Junk folder if you can't find the message in the Inbox.
          </Alert>
          : <Box>
            <Box mb={3}>
              <Typography>E-mail address:</Typography>
              <TextField
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete='email'
                fullWidth
                margin='dense'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <div className='login-form__actions'>
              <Button className='login-form__action--secondary' onClick={() => navigateToLogin(email)} color="info" startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>Back to login</Button>
              <Button variant="contained" color="success" onClick={submit}>Confirm</Button>
            </div>
          </Box>}
  </>
}

export default StartPasswordResetView;