import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { getDefaultRSObject, sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";
import { unfinishedCountSummaryPerClientRSatom } from "../../store/formRequest";
import { DataFormRequest } from "../../dataDefinitions/formRequest";

const BASE_URL = '/formRequest';

export const useFormRequest = () => {
  const [unfinishedCountSummaryPerClientRS, setUnfinishedCountSummaryPerClientRS] = useRecoilState(unfinishedCountSummaryPerClientRSatom);
  const [formRequestsRS, setFormRequestsRS] = useState<RequestState<DataFormRequest[]>>(getDefaultRSObject())
  const [finishFormRequestRS, setFinishFormRequestRS] = useState<RequestState<'OK'>>(getDefaultRSObject())

  const formRequestApi = useMemo(() => ({
    getUnfinishedCountSummaryPerClient: async () => {
      await sendRequestUseRS(setUnfinishedCountSummaryPerClientRS, 'GET', `${BASE_URL}/unfinishedCountSummaryPerClient/`);
    },
    getForClient: async (clientId: number) => {
      await sendRequestUseRS(setFormRequestsRS, 'GET', `${BASE_URL}/forClient/${clientId}`);
    },
    finish: async (requestId: number, comment: string | null) => {
      return await sendRequestUseRS(setFinishFormRequestRS, 'POST', `${BASE_URL}/finish/${requestId}`, {
        body: {
          comment
        }
      });
    },
  }), [setUnfinishedCountSummaryPerClientRS]);

  return {
    unfinishedCountSummaryPerClientRS, 
    formRequestsRS,
    finishFormRequestRS,
    
    formRequestApi
  }
}