import { Stack, Button, Typography, Card, Grid, CardContent, Alert, TextField, Chip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import GenericModal from "../../components/GenericModal";
import { DataFormRequest, DataFormRequestState } from "../../lib/dataDefinitions/formRequest";
import { DataFormDef } from "../../lib/dataDefinitions/formDef";
import InfoElem from "../../components/InfoElem";
import { FormattedDate } from "react-intl";
import { faCheck, faCheckCircle, faClock, faExclamationCircle, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormRun } from "../../lib/hooks/extData/formRun";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import { DataFormRun } from "../../lib/dataDefinitions/formRun";
import FormRunModal from "./FormRunModal";
import { useFormRequest } from "../../lib/hooks/extData/formRequest";
import YesNoDialog from "../../components/YesNoDialog";

const FmtInfoElem: React.FC<{ label: string, value: any }> = ({ label, value }) => <InfoElem label={label} value={value} sx={{ mb: 1 }} />

const DataFormRequestDetailModal: React.FC<{ request: DataFormRequest, defsObj: { [id: string]: DataFormDef }, onClose: (reloadReason: 'REQ_FINISHED' | 'RUN_UPDATED' | null) => void }> = ({ request, defsObj, onClose }) => {

  const { formRunsForRequestRS, deleteRunRS, dismissDeleteError, formRunApi } = useFormRun();
  const { finishFormRequestRS, formRequestApi } = useFormRequest();

  const [preFinishValidationError, setPreFinishValidationError] = useState<string | null>(null);
  const [shouldReloadRequests, setShouldReloadRequests] = useState(false);
  const [clientComment, setClientComment] = useState<string>('');
  const [runFormDetails, setRunFormDetails] = useState<{
    run: DataFormRun | null // null here for a new clean run
  } | null>(null);
  const [formToDeleteId, setFormToDeleteId] = useState<number | null>(null);

  const closeModal = useCallback(() => {
    onClose(shouldReloadRequests ? 'RUN_UPDATED' : null)
  }, [onClose, shouldReloadRequests])

  const onClientCommentChange = (newValue: string) => {
    setPreFinishValidationError(null);
    setClientComment(newValue);
  }
  const startNewRun = () => {
    setPreFinishValidationError(null);
    setRunFormDetails({ run: null });
  }
  const startExistingRun = (run: DataFormRun) => {
    setPreFinishValidationError(null);
    setRunFormDetails({ run });
  }
  const closeRunModal = (wasSaved: boolean) => {
    if (wasSaved) {
      setShouldReloadRequests(true);
      formRunApi.getForFormRequest(request.id);
    }
    setRunFormDetails(null);
  }
  const deleteRun = async () => {
    if (!formToDeleteId) return;
    if (await formRunApi.deleteRun(formToDeleteId)) {
      formRunApi.getForFormRequest(request.id);
      setFormToDeleteId(null)
    }
  }

  const validateToFinish: () => boolean = () => {
    if (!formRunsForRequestRS.data) {
      console.error("Form runs not fetched properly");
      return false;
    }
    const runs = formRunsForRequestRS.data;
    if (runs.some(run => run.req_qn_left_count)) {
      setPreFinishValidationError("Please provide answers to all required questions before finishing the request");
      return false;
    }
    if (runs.length < 1 && !clientComment.trim()) {
      setPreFinishValidationError("Please provide a comment when no form has been filled out for this request");
      return false;
    }
    setPreFinishValidationError(null);
    return true;
  }

  const finishRequest = async () => {
    if (!validateToFinish()) return;
    if (await formRequestApi.finish(request.id, clientComment || null)) {
      onClose('REQ_FINISHED');
    }
  }

  useEffect(() => {
    formRunApi.getForFormRequest(request.id);
    setRunFormDetails(null);
  }, [formRunApi, request])

  const AddResponseBtn: React.FC<{ label: string }> = ({ label }) => <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={startNewRun}>
    {label}
  </Button>;

  return <GenericModal
    title={defsObj[request.form_def_id]?.name || '(name not loaded)'}
    open={true}
    onClose={() => closeModal()} style={{ width: '100%', maxWidth: 900 }}
    showCloseBtn={request.state === DataFormRequestState.FINISHED}
    closeOnlyOnBtn={request.state !== DataFormRequestState.FINISHED}
  >
    {formRunsForRequestRS.pending ? <BoxedCircularProgress align="center" m={2} /> :
      formRunsForRequestRS.error ? <Alert severity="error">{formRunsForRequestRS.error}</Alert> :
        !formRunsForRequestRS.data ? <Alert severity="error">Unknown loading error</Alert> :
          <Box>
            {request.state === DataFormRequestState.FINISHED ?
              <Alert sx={{ mb: 2 }} severity="info">This request and its responses are in <strong>read-only mode</strong> because it was submitted{request.finish_date && <span> on <FormattedDate value={request.finish_date} dateStyle="short" timeStyle="short" /></span>}.</Alert> :
              <Box sx={{ mb: 2 }}>{Boolean(request.allow_multiple_runs) &&
                <Alert severity="info">You can fill out <strong>multiple forms</strong> for this request.</Alert>}
                {/* <Alert severity="info">You can fill out <strong>only one form</strong> for this request.</Alert> */}
              </Box>}
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                {request.create_comment && <Grid item xs={12} md={8}>
                  <FmtInfoElem label="Creator comment" value={request.create_comment} />
                </Grid>}
                <Grid item xs={12} md={4}>
                  <FmtInfoElem label="Requested" value={<FormattedDate value={request.create_date} dateStyle="short" timeStyle="short" />} />
                </Grid>
              </Grid>
              {(request.state === DataFormRequestState.AMEND_REQ) && <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  {request.amend_req_reason && <FmtInfoElem label="Reason for requesting amendment" value={request.amend_req_reason} />}
                </Grid>
                <Grid item xs={12} md={4}>
                  {request.amend_req_date && <FmtInfoElem label="Amendment requested on" value={<FormattedDate value={request.amend_req_date} dateStyle="short" timeStyle="short" />} />}
                </Grid>
              </Grid>}
              {(request.state === DataFormRequestState.FINISHED) && <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <FmtInfoElem label="Responder comment" value={request.finish_comment || '(none)'} />
                </Grid>
                <Grid item xs={12} md={4}>
                  {request.finish_date && <FmtInfoElem label="Finished on" value={<FormattedDate value={request.finish_date} dateStyle="short" timeStyle="short" />} />}
                </Grid>
              </Grid>}
            </Box>
            <Box sx={{ mb: 2 }}>
              {formRunsForRequestRS.data.length ? <Box mb={4}>
                <Stack direction="row" sx={{ mb: 1 }} gap="10px" justifyContent="space-between" flexWrap="wrap">
                  <Typography variant="h5">Saved responses</Typography>
                  <Box>
                    {(request.allow_multiple_runs || formRunsForRequestRS.data.length < 1) && <AddResponseBtn label="fill out another form" />}
                  </Box>
                </Stack>
                <Box>
                  <Grid container spacing={2} alignItems="stretch">
                    {formRunsForRequestRS.data.map((run) => <Grid item key={run.id} xs={12} md={6} lg={4}>
                      <Card sx={{ height: '100%' }}>
                        <CardContent sx={{ height: '100%' }}>
                          <Stack sx={{ height: '100%' }} justifyContent='space-between'>
                            <Box sx={{ mb: 2 }}>
                              <FmtInfoElem label="Started" value={<FormattedDate value={run.start_date} dateStyle="short" />} />
                              <FmtInfoElem label="Last update date" value={<FormattedDate value={run.last_update_date} dateStyle="short" timeStyle="short" />} />
                              <FmtInfoElem label="Last update responder" value={run.last_updater_name} />
                              <FmtInfoElem label="Responder comment" value={run.comment || '(none)'} />
                              <Box mb={1} mt={2}>
                                <Chip
                                  sx={{ width: '100%', mb: 1 }}
                                  variant="outlined"
                                  icon={<Box sx={{ pl: 1 }}>
                                    <FontAwesomeIcon icon={run.req_qn_left_count ? faExclamationCircle : faCheckCircle} />
                                  </Box>}
                                  label={run.req_qn_left_count ? `${run.req_qn_left_count} required question${run.req_qn_left_count !== 1 ? 's' : ''} remain${run.req_qn_left_count !== 1 ? '' : 's'}` : 'all required questions answered'}
                                  color={run.req_qn_left_count ? 'error' : 'success'}
                                />
                                <Box textAlign="center">
                                  {Boolean(run.qn_left_count) && run.qn_left_count !== run.req_qn_left_count &&
                                    <Typography variant="body2">{run.qn_left_count} question{run.qn_left_count !== 1 ? 's' : ''} remain{run.qn_left_count !== 1 ? '' : 's'} in total</Typography>}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Stack direction="column" justifyContent="flex-end" spacing={1}>
                                {request.state === DataFormRequestState.FINISHED ?
                                  <Button variant="outlined" color="primary" onClick={() => startExistingRun(run)}>view answers</Button> :
                                  <>
                                    <Button variant="outlined" color="primary" onClick={() => startExistingRun(run)}>edit answers</Button>
                                    <Button variant="outlined" color="error" onClick={() => setFormToDeleteId(run.id)}>delete answers</Button>
                                  </>}
                              </Stack>
                            </Box>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>)}
                  </Grid>
                </Box>
              </Box> : <Box mb={4}>
                <Typography variant="h5" sx={{ mb: 1 }}>Saved responses</Typography>
                <Box>
                  <AddResponseBtn label="fill out the form" />
                </Box>
              </Box>}
              {request.state !== DataFormRequestState.FINISHED && <Box mb={4}>
                <Typography variant="h5" sx={{ mb: 1 }}>Additional comments</Typography>
                <TextField
                  fullWidth
                  label={`Type your comments to this request here (${formRunsForRequestRS.data.length ? 'optional' : 'mandatory when no response given'})`}
                  value={clientComment}
                  required={!formRunsForRequestRS.data.length}
                  onChange={e => onClientCommentChange(e.target.value)}
                  variant="outlined"
                  inputProps={{ maxLength: 250 }}
                />
              </Box>}
            </Box>
            {request.state !== DataFormRequestState.FINISHED && <Box>
              {preFinishValidationError && <Alert sx={{ mb: 2 }} severity="error">{preFinishValidationError}</Alert>}
              {!finishFormRequestRS.pending && finishFormRequestRS.error && <Alert sx={{ mb: 2 }} severity="error">{finishFormRequestRS.error}</Alert>}
              {finishFormRequestRS.pending ? <BoxedCircularProgress align="center" m={2} /> :
                <Stack sx={{ mt: 1 }} gap="5px" direction="row" justifyContent="center" flexWrap="wrap">
                  <Button variant="outlined" color="success" startIcon={<FontAwesomeIcon icon={faCheck} />} onClick={finishRequest}>
                    finish {formRunsForRequestRS.data.length ? formRunsForRequestRS.data.length < 2 ? '& submit response' : '& submit responses' : 'without a response'}
                  </Button>
                  <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={faClock} />} onClick={closeModal}>
                    finish later
                  </Button>
                </Stack>}
            </Box>}
          </Box>}
    {runFormDetails && <FormRunModal run={runFormDetails.run} request={request} onClose={closeRunModal} />}
    {formToDeleteId && <>
      {deleteRunRS.pending || deleteRunRS.error ? <GenericModal title={deleteRunRS.pending ? '' : 'An error occured'} showCloseBtn={!deleteRunRS.pending} closeOnlyOnBtn={deleteRunRS.pending} open={true} onClose={() => { dismissDeleteError(); setFormToDeleteId(null) }}>
        {deleteRunRS.pending ? <BoxedCircularProgress align="center" m={2} /> : <Alert sx={{ mb: 2 }} severity="error">{deleteRunRS.error}</Alert>}
      </GenericModal> :
        <YesNoDialog isOpen={Boolean(formToDeleteId)} title="Confirmation" message="Are you sure to delete this response?" yesAction={() => deleteRun()} noAction={() => setFormToDeleteId(null)} />}
    </>}
  </GenericModal>
}

export default DataFormRequestDetailModal;