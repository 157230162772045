import { Stack, Button, Typography, Alert, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import GenericModal from "../../components/GenericModal";
import FormGeneratorContainer from "../../components/FormGenerator/FormGeneratorContainer";
import { DataFormRequest, DataFormRequestState } from "../../lib/dataDefinitions/formRequest";
import { faInfoCircle, faSave, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormRun } from "../../lib/hooks/extData/formRun";
import BoxedCircularProgress from "../../components/BoxedCircularProgress";
import { DataFormRun, DataFormRunValues } from "../../lib/dataDefinitions/formRun";
import { useFormDef } from "../../lib/hooks/extData/formDef";
import { RequestState } from "../../lib/dataDefinitions/request";
import { getEmptyFormRunValuesObj, getQuestionsWithInvalidOptions, mapChoiceLabelsToValues, mapChoiceValuesToLabels } from "../../lib/features/dataForms/values";
import { DataFormDef, DataFormQnsWithInvalidOptions } from "../../lib/dataDefinitions/formDef";
import { getFormDefWithNonexistentOptions } from "../../lib/features/dataForms/definition";

const FormRunModal: React.FC<{ run: DataFormRun | null, request: DataFormRequest, onClose: Function }> = ({ run, request, onClose }) => {

  const { formRunValuesRS, existingClientValuesRS, createRunForRequestRS, updateRunRS, formRunApi } = useFormRun();
  const { formDefForRequestRS, formDefApi } = useFormDef();
  const [formDefAdjusted, setFormDefAdjusted] = useState<DataFormDef | null>(null);

  const [formDataFetchingRS, setFormDataFetchingRS] = useState<RequestState<null>>({ pending: true, error: '', data: null });
  const [formValues, setFormValues] = useState<DataFormRunValues>({});
  const [preFinishValidationError, setPreFinishValidationError] = useState<string | null>(null);

  const [clientComment, setClientComment] = useState<string>('');

  const closeModal = (wasSaved: boolean) => {
    onClose(wasSaved);
  }

  const saveRun = async () => {
    const formDef = formDefForRequestRS.data;
    if (!formDef) {
      console.error("Initial form definition data not loaded!")
      return;
    }
    // Do not save if there are invalid questions or options selected
    if ((formDef.sections || []).map(section => section.questions || []).flat().some(qn => qn.isInvalidForMissingField)) {
      setPreFinishValidationError("The form has some invalid questions and cannot be saved.");
      return;
    }
    const varNameWithInvalidChoice = Object.keys(formValues).find(key => formValues[key] === '#INVALID#')
    const questions = (formDef.sections || []).map(section => section.questions || []).flat()
    if (varNameWithInvalidChoice) {
      const question = questions.find(qn => qn.varName === varNameWithInvalidChoice);
      setPreFinishValidationError(`The option chosen for question '${question?.label}' is not allowed. Please choose a different one before saving.`);
      return;
    }

    const values = mapChoiceValuesToLabels(formValues, formDef);
    // console.log("Questions:", questions, values)
    let wasSuccessful: boolean = false;
    if (run) {
      wasSuccessful = await formRunApi.updateRun(run.id, values, clientComment ? clientComment : null);
    } else {
      wasSuccessful = await formRunApi.createRunForRequest(request.id, values, clientComment ? clientComment : null);
    }
    if (wasSuccessful) {
      closeModal(true);
    }
  }

  const changeValue = (varName: string, newValue: any) => {
    setFormValues({
      ...formValues,
      [varName]: newValue,
    })
    console.log("Curr vals:", formValues)
  }

  // Assemble final fields and values
  useEffect(() => {
    if (run && (!formRunValuesRS.data || formRunValuesRS.pending)) {
      if (formRunValuesRS.error) {
        setFormDataFetchingRS({ pending: false, error: 'Error loading response values: ' + formRunValuesRS.error });
        return;
      }
      setFormDataFetchingRS({ pending: true, error: null });
      return; // pending
    }
    if (request.prepopulate_current_values && !run && (!existingClientValuesRS.data || existingClientValuesRS.pending)) {
      if (existingClientValuesRS.error) {
        setFormDataFetchingRS({ pending: false, error: 'Error loading client information: ' + existingClientValuesRS.error });
        return;
      }
      setFormDataFetchingRS({ pending: true, error: null });
      return; // pending
    }
    if (!formDefForRequestRS.data || formDefForRequestRS.pending) {
      if (formDefForRequestRS.error) {
        setFormDataFetchingRS({ pending: false, error: 'Error loading form definition: ' + formDefForRequestRS.error });
        return;
      }
      setFormDataFetchingRS({ pending: true, error: null });
      return; // pending
    }
    console.log("Loaded all data, processing now...")

    // Prepare fields and values
    const formDef = formDefForRequestRS.data;
    if (!formDef.sections) return; // no sections means an empty form
    const formRunValues = formRunValuesRS.data || {};

    // Look for invalid options
    const qnsWithInvalidOptions: DataFormQnsWithInvalidOptions = getQuestionsWithInvalidOptions(formDef, formRunValues);
    // Add '#INVALID#' options where necessary
    const formDefAdjusted = getFormDefWithNonexistentOptions(formDef, qnsWithInvalidOptions, request.state === DataFormRequestState.FINISHED);
    // Save form def extended by '#INVALID#' options
    setFormDefAdjusted(formDefAdjusted);

    const values: DataFormRunValues = {
      ...getEmptyFormRunValuesObj(formDef),
      ...existingClientValuesRS.data,
      ...mapChoiceLabelsToValues(formRunValues, formDef)
    }
    setFormValues(values);

    setFormDataFetchingRS({ pending: false, error: null, data: null });
  }, [run, request, formRunValuesRS, existingClientValuesRS, formDefForRequestRS])

  useEffect(() => {
    if (!run) return;
    console.log("Fetching data for run...", run.id)
    formRunApi.getValues(run.id);
  }, [formRunApi, run])

  useEffect(() => {
    console.log("Fetching data for request...", request.id)
    formDefApi.getForFormRequest(request.id);
    if (request.prepopulate_current_values && !run) {
      // Don't pre-populate for existing run
      formRunApi.getExistingClientValues(request.id);
    }
  }, [formDefApi, formRunApi, request, run])

  return <GenericModal title={formDefForRequestRS.data?.name || (formDefForRequestRS.pending ? 'Loading...' : '(name not loaded)')}
    open={true}
    onClose={() => closeModal(false)} style={{ width: '100%', maxWidth: 900 }}
    showCloseBtn={request.state === DataFormRequestState.FINISHED || Boolean(formDataFetchingRS.error)}>
    {formDataFetchingRS.pending ? <BoxedCircularProgress align="center" m={2} /> :
      formDataFetchingRS.error ? <Alert severity="error">{formDataFetchingRS.error}</Alert> :
        !formDefAdjusted ? <Alert severity="error">Unknown loading error</Alert> :
          <Box>
            <Box mb={4}>
              <Box mb={2}>
                {formDefAdjusted.description &&
                  <Box mb={1}>
                    {formDefAdjusted.description.split('\n').map((line, lineIndex) => <Typography key={lineIndex} sx={{ mb: 0 }} variant="body1">{line}</Typography>)}
                  </Box>
                }
                <Typography variant="body1"><FontAwesomeIcon icon={faInfoCircle} /> Fields marked with red asterisk (<span style={{ color: 'red' }}>*</span>) are mandatory.</Typography>
              </Box>
              <Box>
                {!formDefAdjusted.sections ? <Alert severity="error">No form sections or questions defined. Please contact your accountant.</Alert> :
                  formDefAdjusted.sections.filter(section => section.questions?.length).map((section, sectionIndex) => <Box mb={5} key={section.id}>
                    <Box mb={1}>
                      <Typography variant="h5">{section.label}</Typography>
                      {section.description &&
                        <Box mt={1}>
                          {section.description.split('\n').map((line, lineIndex) => <Typography key={lineIndex} sx={{ mb: 0 }} variant="body1">{line}</Typography>)}
                        </Box>
                      }
                    </Box>
                    <Box>
                      {section.questions && <FormGeneratorContainer
                        fields={section.questions}
                        inputValues={formValues}
                        inReadOnlyMode={request.state === DataFormRequestState.FINISHED}
                        showQuestionOrdinals={true}
                        onPropagateValueChange={changeValue}
                      />}
                    </Box>
                  </Box>)}
              </Box>
              <Box>
                <Typography variant="h5" sx={{ mb: 1 }}>Additional comments</Typography>
                <Box>
                  <TextField
                    fullWidth
                    label="Type your comments to this form response here (optional)"
                    value={clientComment}
                    onChange={e => setClientComment(e.target.value)}
                    variant="outlined"
                    inputProps={{ maxLength: 250 }}
                  />
                </Box>
              </Box>
            </Box>
            {preFinishValidationError && <Alert sx={{ mb: 2 }} severity="error">{preFinishValidationError}</Alert>}
            {request.state !== DataFormRequestState.FINISHED && <Box py={2}>
              {createRunForRequestRS.error && <Alert severity="error">{createRunForRequestRS.error}</Alert>}
              {updateRunRS.error && <Alert severity="error">{updateRunRS.error}</Alert>}
              {(createRunForRequestRS.pending || updateRunRS.pending) ? <BoxedCircularProgress align="center" m={2} /> : <Box>
                <Stack sx={{ mt: 1 }} spacing={1} direction="row" justifyContent="center">
                  <Button variant="outlined" color="success" startIcon={<FontAwesomeIcon icon={faSave} />} onClick={() => saveRun()}>
                    save
                  </Button>
                  <Button variant="outlined" color="error" startIcon={<FontAwesomeIcon icon={faTimes} />} onClick={() => closeModal(false)}>
                    cancel
                  </Button>
                </Stack>
              </Box>}
            </Box>}
          </Box>}
  </GenericModal>
}

export default FormRunModal;