import { useMemo, useState } from "react"
import { sendRequest, sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";

export const useRegister = () => {

  const [registrationReqState, setRegistrationReqState] = useState<RequestState<{ newUserId: number }>>({ pending: false, error: '', data: null });
  const [signupUidCheckRS, setSignupUidCheckRS] = useState<RequestState<{ isValid: boolean }>>({ pending: false, error: '', data: null })

  const registerApi = useMemo(() => ({
    register: async (uid: string, email: string, password: string) => {
      setRegistrationReqState({ pending: true, error: '', data: null });
      const { data, error } = await sendRequest('POST', '/auth/register', { body: { uid, email, password } });
      if (error) {
        console.log("ERR", error)
        setRegistrationReqState({ pending: false, error: error?.toString() || 'Unknown error', data: null });
        return;
      }
      setRegistrationReqState({ pending: false, error, data })
    },
    checkSignupUid: async (uid: string) => {
      return await sendRequestUseRS(setSignupUidCheckRS, 'GET', `/auth/checkSignupUid/${uid}`, { includeToken: false });
    }
  }), []);

  return {
    registrationReqState,
    signupUidCheckRS,
    registerApi,
  };
}