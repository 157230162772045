import { useMemo, useState } from "react";
import { sendRequestUseRS } from "../../apiClient";
import { RequestState } from "../../dataDefinitions/request";
import { ServiceDefinition } from "../../dataDefinitions/serviceDefinition";

export const useServiceDefinitionsObj = () => {
  const [serviceDefsObjRS, setServiceDefsObjRS] = useState<RequestState<{[symbol:string]: ServiceDefinition}>>({ pending: false, error: '', data: null })

  const serviceDefsApi = useMemo(() => ({ 
    getServiceDefs: async () => {
      const dataPipe: Function[] = [
        (data: ServiceDefinition[]) => data.reduce((acc, curr) => ({ ...acc, [curr.symbol]: curr }) ,{})
      ]
      return await sendRequestUseRS(setServiceDefsObjRS, 'GET', `/serviceDefinition/`, { dataPipe });
    },
  }), []);

  return { serviceDefsObjRS, serviceDefsApi }
}