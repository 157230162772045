import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import TextField from '@mui/material/TextField'
import { useState, useCallback, useEffect } from "react";
import { useAuth } from "../lib/hooks/extData/auth";
import BoxedCircularProgress from "../components/BoxedCircularProgress";
import Button from '@mui/material/Button'
import QrCode from "../components/QrCode";

const SetUp2FAView = () => {
  const [firstCode, setFirstCode] = useState('');
  const [loggedInUserReloading, setLoggedInUserReloading] = useState(false);
  const { loggedInUser, setup2faRS, confirm2faRS, disable2faRS, authApi } = useAuth();

  const confirm2FA = useCallback(async () => {
    // remove non-digits
    const isSuccess = await authApi.confirm2FA(firstCode.replace(/\D/g, ''));
    if (isSuccess) {
      console.log("Refreshing user")
      setLoggedInUserReloading(true);
      authApi.cancelSetup2FA();
      authApi.refreshUser();
    }
  }, [authApi, firstCode])

  const disable2FA = useCallback(async () => {
    const isSuccess = await authApi.disable2FA();
    if (isSuccess) {
      console.log("Refreshing user")
      setLoggedInUserReloading(true);
      authApi.refreshUser();
    }
  }, [authApi])

  useEffect(() => {
    if (loggedInUser) {
      setLoggedInUserReloading(false)
    }
  }, [loggedInUser]);

  return <Box>
    <Typography variant="h4" sx={{ mb: 3 }}>Set up Two-Factor Authentication</Typography>
    {!setup2faRS.data && !setup2faRS.pending && !disable2faRS.pending && <Box sx={{ mb: 3 }}>
      {loggedInUserReloading ? <BoxedCircularProgress align="left" message="Loading 2FA status..." /> :
        loggedInUser?.totp_enabled ? <Box>
          <Alert severity="success" sx={{ mb: 2 }}>
            2FA is already set up.
          </Alert>
          <Button variant="outlined" color="error" onClick={() => disable2FA()}>
            disable
          </Button>
        </Box> : <Box>
          <Alert severity="error" sx={{ mb: 2 }}>
            2FA is not set up yet.
          </Alert>
          <Button variant="outlined" color="success" onClick={() => authApi.setup2FA()}>
            enable
          </Button>
        </Box>}
    </Box>}
    {disable2faRS.pending ? <Box>
      <BoxedCircularProgress align="left" message="Disabling Two-Factor Authentication..." />
    </Box> : disable2faRS.error ?
      <Alert severity="error">{disable2faRS.error}</Alert> : ''}
    {setup2faRS.pending ? <Box>
      <BoxedCircularProgress align="left" message="Fetching QR code..." />
    </Box> : setup2faRS.data ? <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">1. Download the authenticator app from AppStore or Google Play if you don't have it already.</Typography>
        <Typography>Compatible products are for example: <strong>Google Authenticator</strong> or <strong>Microsoft Authenticator</strong>.</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">2. Scan the code below with your authenticator app:</Typography>
        <Box sx={{ my: 1 }}>
          <QrCode textToEncode={setup2faRS.data.otpUrl} />
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>3. Type the code returned by the authenticator app in a text field below:</Typography>
        <TextField label="Authenticator app code" value={firstCode} onChange={e => setFirstCode(e.target.value)} onKeyUp={e => e.key === 'Enter' ? confirm2FA() : null} />
      </Box>
      <Box>
        {confirm2faRS.pending ?
          <BoxedCircularProgress align="left" message="Sending code..." /> :
          <Box sx={{ mb: 2 }}>
            <Button sx={{ mr: 1 }} variant="outlined" color="success" onClick={() => confirm2FA()}>
              confirm
            </Button>
            <Button variant="outlined" color="error" onClick={() => authApi.cancelSetup2FA()}>
              cancel
            </Button>
          </Box>}
        {confirm2faRS.error && <Alert severity="error">{confirm2faRS.errorCode === 401 ? 'Invalid code. Try again.' : confirm2faRS.error}</Alert>}
      </Box>
    </Box> : ''}
  </Box>
}

export default SetUp2FAView;