import { Box, Divider, TextField, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, FormHelperText, Autocomplete, Typography } from "@mui/material"
import { FormGeneratorField, FormGeneratorFieldOption, FormGeneratorFieldValues } from "../../lib/dataDefinitions/formGenerator"
import { faExclamationCircle, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormGeneratorAddressField from "./FormGeneratorAddressField";
import FormGeneratorMultipleChoiceField from "./FormGeneratorMultipleChoiceField";
import FormGeneratorDateTimeField from "./FormGeneratorDateTimeField";

const FormGeneratorContainer: React.FC<{
    fields: FormGeneratorField[],
    inputValues: FormGeneratorFieldValues,
    inReadOnlyMode?: boolean,
    showQuestionOrdinals?: boolean,
    onPropagateValueChange: (varName: string, newValue: any) => any
}> = ({
    fields,
    inputValues,
    inReadOnlyMode = false,
    showQuestionOrdinals = false,
    onPropagateValueChange
}) => {
        // PLEASE NOTE: This does NOT support all types from data definitions, including: email, color, switch, frequency

        return <Box>
            <Box>{fields.map((field, fieldIndex) => <Box key={fieldIndex} sx={{ mb: 3 }}>
                {field.type === 'separator' ? <Divider /> :
                    <FormControl fullWidth={true}>
                        <Box className="question-header" sx={{ mb: 1 }}>
                            <FormLabel>{showQuestionOrdinals ? `${fieldIndex + 1}. ` : ''}{field.label} {field.isRequired && <span style={{ color: 'red' }}>*</span>}</FormLabel>
                            {field.hint && <FormHelperText><FontAwesomeIcon icon={faInfoCircle} /> {field.hint}</FormHelperText>}
                        </Box>
                        <Box className="question-input" sx={{ pr: 2 }}>
                            {field.type === 'text' && <Box>
                                <TextField
                                    fullWidth={true}
                                    value={inputValues[field.varName] || ''}
                                    onChange={(e) => onPropagateValueChange(field.varName, e.target.value)}
                                />
                            </Box>}
                            {field.type === 'number' && <Box>
                                <TextField
                                    type="number"
                                    fullWidth={true}
                                    value={inputValues[field.varName] == null ? '' : inputValues[field.varName]}
                                    onChange={(e) => onPropagateValueChange(field.varName, (field.specifics.withFraction ? parseFloat(e.target.value) : parseInt(e.target.value)) || 0)}
                                />
                            </Box>}
                            {field.type === 'longtext' && <Box>
                                <TextField
                                    fullWidth={true}
                                    multiline={true}
                                    minRows={3}
                                    value={inputValues[field.varName] || ''}
                                    onChange={(e) => onPropagateValueChange(field.varName, e.target.value)}
                                />
                            </Box>}
                            {field.type === 'date' && <Box>
                                <FormGeneratorDateTimeField
                                    withTime={false}
                                    rawValue={inputValues[field.varName]}
                                    onPropagateValueChange={(newValue) => onPropagateValueChange(field.varName, newValue)}
                                />
                            </Box>}
                            {field.type === 'datetime' && <Box>
                                <FormGeneratorDateTimeField
                                    withTime={true}
                                    rawValue={inputValues[field.varName]}
                                    onPropagateValueChange={(newValue) => onPropagateValueChange(field.varName, newValue)}
                                />
                            </Box>}
                            {field.type === 'address' && <Box>
                                <FormGeneratorAddressField
                                    value={inputValues[field.varName]}
                                    onPropagateValueChange={(newValue) => onPropagateValueChange(field.varName, newValue)}
                                />
                            </Box>}
                            {field.type === 'select' && <Box>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={field.specifics.options}
                                    getOptionDisabled={option => Boolean(option.isDisabled)}
                                    fullWidth={true}
                                    value={inputValues[field.varName] === null ? null : field.specifics.options.find(option => option.value === inputValues[field.varName].toString())}
                                    onChange={(_, selectedOption: FormGeneratorFieldOption | null) => onPropagateValueChange(field.varName, selectedOption?.value || null)}
                                    renderInput={(params) => <TextField {...params} placeholder="(not specified)" />}
                                />
                            </Box>}
                            {field.type === 'radios' && <Box>
                                <RadioGroup
                                    value={inputValues[field.varName]}
                                    onChange={(e) => onPropagateValueChange(field.varName, e.target.value === 'null' ? null : e.target.value)}
                                >
                                    <FormControlLabel value="null" control={<Radio />} label="(not specified)" />
                                    {field.specifics.options.map(option =>
                                        <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />)}
                                </RadioGroup>
                            </Box>}
                            {field.type === 'checkboxes' && <Box>
                                <FormGeneratorMultipleChoiceField
                                    options={field.specifics.options}
                                    currSelection={inputValues[field.varName]}
                                    onPropagateValueChange={(newValue) => onPropagateValueChange(field.varName, newValue)}
                                />
                            </Box>}
                        </Box>
                        {!inReadOnlyMode && field.isInvalidForMissingField && <Box mt={1}>
                            <Typography variant="body2" color="error"><FontAwesomeIcon icon={faExclamationCircle} /> This question is invalid as the linked field does not exist, please check with your accountant.</Typography>
                        </Box>}
                    </FormControl>}
            </Box>)}</Box>
        </Box>
    }

export default FormGeneratorContainer;